import React, { createContext, useContext, useState, useEffect } from 'react';
import axios, { AxiosInstance } from 'axios';
import { AuthContext } from '../context/authContext';
import Loading from '../componenents/Loading';

interface AxiosInstanceContextProps {
    axiosInstance: AxiosInstance;
}

const AxiosInstanceContext = createContext<AxiosInstanceContextProps | undefined>(undefined);

export const useAxiosInstance = () => {
    const context = useContext(AxiosInstanceContext);
    if (!context) {
        throw new Error('useAxiosInstance must be used within an AxiosInstanceProvider');
    }
    return context.axiosInstance;
};

interface ProviderProps {
    children: React.ReactNode
}

export const AxiosInstanceProvider: React.FC<ProviderProps> = ({ children }) => {
    const { currentUser, idToken, idTokenResult } = useContext(AuthContext);
    const [axiosInstance, setAxiosInstance] = useState(() => {
        const instance = axios.create({
            baseURL: process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_API : process.env.REACT_APP_PROD_API,
            headers: {
                Authorization: `Bearer ${idToken}`,
            },
        });

        const refreshAccessToken = async () => {
            try {
                if (currentUser) {
                    const refreshedUser = await currentUser.getIdToken(true);
                    if (refreshedUser) {
                        return refreshedUser;
                    }
                }
            } catch (error) {
                console.error('Token refresh error:', error);
            }
            return null;
        };

        instance.interceptors.request.use(
            async (config) => {
                config.headers.setContentType('application/json');
                if (idTokenResult) {
                    const exp = new Date(idTokenResult?.expirationTime);
                    if (Date.now() >= exp.getTime()) {
                        const newToken = await refreshAccessToken();
                        if (newToken) {
                            config.headers.Authorization = `Bearer ${newToken}`;
                        }
                    }
                }
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );
        return instance;
    });

    return (
        <AxiosInstanceContext.Provider value={{ axiosInstance }}>
            {children}
        </AxiosInstanceContext.Provider>
    );
};
