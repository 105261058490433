import { ArrowRight, CheckCircle, PanoramaFishEyeOutlined, RemoveCircle } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Typography, Divider, Grid, Collapse, Button, Tooltip, IconButton, Chip } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { DateTime } from 'luxon';
import { CaseType, SessionType, User } from '../../types';
import SessionsList from './SessionsList';
import { getDisplayCaseState, getDisplayCaseStateNotes } from '../ClientsTab/ClientsTable';
import Session from '../SessionsTab/Session';
import { DialogsContext } from '../../context/dialogsContext';
import { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { UserContext } from '../../context/userContext';
import { WaiverContext } from '../../context/waiversContext';
import { ClientsContext } from '../../context/clientsContext';
import ConfirmDialog from '../ConfirmDialog';

interface CaseRowItemProps {
    rowCase: CaseType;
    rowCaseSessions: SessionType[];
    toggleStatusRow: (rowCaseId: number) => void;
    toggleSessionRow: (rowCaseId: number) => void;
    openStatusRows: number[];
    openSessionRows: number[];
}


const CaseRowItem = ({
    rowCase,
    rowCaseSessions,
    toggleStatusRow,
    toggleSessionRow,
    openStatusRows,
    openSessionRows
}: CaseRowItemProps) => {
    const { user } = useContext(UserContext);
    const createdDate = DateTime.fromISO(rowCase.created_date).toLocaleString(DateTime.DATE_SHORT);
    const [openDescriptionRows, setOpenDescriptionRows] = useState<number[]>([]);
    const { caseStatusDialog, setCaseStatusDialog, showManageCaseDialog } = useContext(DialogsContext);
    const { clients, resendWaiver } = useContext(ClientsContext);
    const [showResendCaseWaiverDialog, setShowResendCaseWaiverDialog] = useState(false);
    const [caseStatus, setCaseStatus] = useState<string | null>(null);
    const [reviewers, setReviewers] = useState<User[]>([])


    // const isDescriptionOpen = openDescriptionRows.includes(rowCase.id);
    const isStatusOpen = openStatusRows.includes(rowCase.id);
    const isSessionsOpen = openSessionRows.includes(rowCase.id);

    // const toggleDescriptionRow = (id: number) => {
    //     if (openDescriptionRows.includes(id)) {
    //         setOpenDescriptionRows(_.remove(openDescriptionRows, (existingId) => existingId != id));
    //     } else {
    //         setOpenDescriptionRows([...[id], ...openDescriptionRows]);
    //     }
    // };

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const isEmail = (email: string): boolean => {
        return emailRegex.test(email);
    };

    useEffect(() => {
        if (rowCase) {
            setCaseStatus(getDisplayCaseState(rowCase));
            if (rowCase.statuses && rowCase.statuses.length > 0) {
              const statuses = rowCase.statuses.filter(
                (s) => !['UNSUBMITTED', 'SUBMITTED'].includes(s.state),
              )
              const reviewers = statuses.map((s) => (s.setter as User))
              setReviewers(_.unionBy(reviewers, 'id'));
            }
        }
    }, [rowCase, clients]);

    const RowButton = ({ label }: { label: string }) => {
        return (
            <Button sx={{ m: 0, p: 0, ml: 1 }}>{label}</Button>
        )
    }

    const iconStyle = { fontSize: 14 };

    const getWaiverResult = () => {
        const client = clients.find((c) => c.id === (typeof rowCase.client === 'number' ? rowCase.client : rowCase.client.id));
        if (client?.email && isEmail(client?.email)) {
            const wr = client?.waivers_results.find((wr) => wr.case_id === rowCase.id && wr.waiver_id === 2);
            if (wr?.accepted === 'Y') {
                return (
                    <Tooltip title='Client accepted healing waiver terms'>
                        <CheckCircle color='success' sx={iconStyle} />
                    </Tooltip>
                )
            } else if (wr?.accepted === 'N') {
                return (
                    <Tooltip title='Client has declined to accept healing waiver terms'>
                        <RemoveCircle color='error' sx={iconStyle} />
                    </Tooltip>
                )
            } else {
                return (
                    <Tooltip title='Client has not completed healing waiver'>
                        <PanoramaFishEyeOutlined color='action' sx={iconStyle} />
                    </Tooltip>
                )
            }
        } else {
            return <></>;
        }
    }

    const GridDivider = () => {
        return (
            <Grid item xs={12} sm={12} md={12} >
                <Divider sx={{ my: 1 }} />
            </Grid>
        )
    }

    return (
        <Box key={rowCase.id} sx={{ flexDirection: 'row' }}>
            <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                <Box m={2} sx={{ flexDirection: 'column' }}>
                    <Grid container spacing={1}>
                        <Grid item container xs={12} sm={12} md={12} sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                {getWaiverResult() && <Button disableFocusRipple disableRipple variant={'text'} sx={{ m: 0, p: 0, minWidth: 0, pb: 0.7, pr: 0.5 }} onClick={() => {
                                    const client = clients.find((c) => c.id === rowCase.client);
                                    const wr = client?.waivers_results.find((wr) => wr.case_id === rowCase.id && wr.waiver_id === 2);
                                    if (wr?.accepted !== 'Y' && !user.is_case_reviewer) {
                                        setShowResendCaseWaiverDialog(true);
                                    }
                                }}>
                                    {getWaiverResult()}
                                </Button>}
                                <Typography color={'CaptionText'} variant='overline' sx={{ fontWeight: 'bold' }}>{!user.is_case_reviewer && 'NAME'}</Typography>
                            </Box>
                            {!user.is_case_reviewer && <Typography color={'CaptionText'} variant='subtitle1'>{user.is_case_reviewer ? rowCase.id + ': ' + rowCase.name : rowCase.name}</Typography>}
                            {user.is_case_reviewer &&
                                <>
                                    <Typography color={'CaptionText'} variant='overline' sx={{ fontWeight: 'bold' }}>case</Typography>
                                    <Typography color={'CaptionText'} variant='subtitle1'>{rowCase.id + ': ' + rowCase.name}</Typography>

                                    <Typography color={reviewers.length > 0 ? 'CaptionText' : 'steelblue'} variant='overline' sx={{ fontWeight: 'bold' }}>
                                      {reviewers.length > 0 ? 'reviewed by' : 'not yet reviewed'}
                                    </Typography>
                                    {reviewers.map((r) => (
                                      <Typography key={r.full_name} color={'CaptionText'} variant='subtitle1'>
                                        {r.full_name}
                                      </Typography>
                                    ))}

                                    <Typography color={'CaptionText'} variant='overline' sx={{ fontWeight: 'bold' }}>healer</Typography>
                                    <Typography color={'CaptionText'} variant='subtitle1'>{rowCase.healer ? (rowCase.healer as User).full_name : '--'}</Typography>

                                    <Typography color={'CaptionText'} variant='overline' sx={{ fontWeight: 'bold' }}>purpose</Typography>
                                    <Typography color={'CaptionText'} variant='subtitle1'>{rowCase.purpose || '--'}</Typography>
                                </>
                            }
                        </Grid>
                        <Grid item container xs={12} sm={12} md={12} >
                            <GridDivider />
                            <Grid item xs={6} sm={6} md={2}>
                                <Typography color={'CaptionText'} variant='overline' sx={{ fontWeight: 'bold' }}>TYPE</Typography>
                                <Typography color={'CaptionText'} variant='subtitle1'>{rowCase.type}</Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={2}>
                                <Typography color={'CaptionText'} variant='overline' sx={{ fontWeight: 'bold' }}>STATUS</Typography>
                                <Typography color={'CaptionText'} variant='subtitle1'>{caseStatus}</Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={2}>
                                <Typography color={'CaptionText'} variant='overline' sx={{ fontWeight: 'bold' }}>REMOTE</Typography>
                                <Typography color={'CaptionText'} variant='subtitle1'>{rowCase.remote === 'Y' ? 'yes' : 'no'}</Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={2}>
                                <Typography color={'CaptionText'} variant='overline' sx={{ fontWeight: 'bold' }}>CREATED</Typography>
                                <Typography color={'CaptionText'} variant='subtitle1'>{createdDate}</Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={2}>
                                <Typography color={'CaptionText'} variant='overline' sx={{ fontWeight: 'bold' }}>CATEGORIES</Typography>
                                <Stack direction="row" spacing={1}>
                                    {rowCase.categories && rowCase.categories.length > 0 ?
                                        rowCase.categories.map((c) => <Chip key={c.id} label={c.name} size='small' variant='outlined' />) : <Typography>none</Typography>
                                    }
                                </Stack>
                            </Grid>
                            <Grid item xs={6} sm={6} md={2}>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'row'
                                }}>
                                    {user.is_case_reviewer &&
                                        (
                                            <Button
                                                onClick={() => {
                                                    setCaseStatusDialog({ show: true, case: rowCase })
                                                }}
                                                sx={{ mt: 0, pt: 0, mr: 0, pr: 0, mb: 0, pb: 0 }}
                                            >
                                                <Typography color={'CaptionText'} variant='overline' sx={{ fontWeight: 'bold', textAlign: 'center' }}>Review</Typography>
                                                <ArrowRight sx={{ fontSize: 24, mb: 0.45 }} />
                                            </Button>
                                        )
                                    }
                                    {!user.is_case_reviewer && caseStatus !== 'approved' && (

                                        <Button
                                            onClick={() => showManageCaseDialog({
                                                show: true, caseId: rowCase.id
                                            })}
                                            sx={{ mt: 0, pt: 0, mr: 0, pr: 0, mb: 0, pb: 0 }}
                                        >
                                            <Typography color={'CaptionText'} variant='overline' sx={{ fontWeight: 'bold', textAlign: 'center' }}>Manage</Typography>
                                            <ArrowRight sx={{ fontSize: 24, mb: 0.45 }} />
                                        </Button>
                                    )}

                                </Box>
                            </Grid>
                            <GridDivider />
                        </Grid>

                        {caseStatus !== 'none' && (
                            <>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <Typography color={'CaptionText'} variant='overline' sx={{ fontWeight: 'bold' }}>{user.is_case_reviewer ? 'Status History' : 'Status Description'}</Typography>
                                        <Box pb={0.4} onClick={() => toggleStatusRow(rowCase.id)} sx={{ cursor: 'pointer' }}>
                                            <RowButton label={isStatusOpen ? 'close' : 'open'} />
                                        </Box>
                                    </Box>
                                    <Collapse sx={{ width: '100%' }} in={isStatusOpen} unmountOnExit>
                                        {user.is_case_reviewer ? (
                                            <Grid container spacing={1}>
                                                {rowCase.statuses.slice().reverse().map((status) =>
                                                    <Grid item key={status.id + status.created_date} xs={12} sm={12} md={12}>
                                                        <Box sx={{ width: '100%', mb: 2 }}>
                                                            <Typography variant='subtitle2'>{status.state}</Typography>
                                                            <Typography variant='subtitle2'>{status.setter ? (status.setter as User).full_name : ''}</Typography>
                                                            <Typography>{DateTime.fromISO(status.created_date).toLocaleString(DateTime.DATETIME_FULL)}</Typography>
                                                            <Typography>{status.notes}</Typography>
                                                        </Box>
                                                    </Grid>
                                                )}
                                            </Grid>


                                        ) : (
                                            <Typography>{getDisplayCaseStateNotes(rowCase)}</Typography>
                                        )}


                                    </Collapse>
                                </Grid>
                                <GridDivider />
                            </>
                        )}
                        <Grid item xs={12} sm={12} md={12}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Typography color={'CaptionText'} variant='overline' sx={{ fontWeight: 'bold' }}>{`${rowCaseSessions.length} Sessions`}</Typography>
                                <Box pb={0.4} onClick={() => toggleSessionRow(rowCase.id)} sx={{ cursor: 'pointer' }}>
                                    <RowButton label={isSessionsOpen ? 'close' : 'open'} />
                                </Box>
                            </Box>
                            <Collapse sx={{ width: '100%' }} in={isSessionsOpen} unmountOnExit>
                                <SessionsList clientCase={rowCase} caseSessions={rowCaseSessions} />
                            </Collapse>
                        </Grid>
                    </Grid>
                </Box >
            </Box >
            <ConfirmDialog
                open={showResendCaseWaiverDialog}
                title='Case Waiver'
                message='Would you like to resend case waiver?'
                onConfirm={() => {
                    setShowResendCaseWaiverDialog(false);
                    resendWaiver(rowCase.id);
                }}
                onCancel={() => {
                    setShowResendCaseWaiverDialog(false);
                }}
            />
        </Box >
    )
}

export default CaseRowItem;