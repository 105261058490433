import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Toolbar from './ToolBar'
import AppBar from './AppBar'
import { Dialog, useMediaQuery } from '@mui/material'
import { AutoGraph } from '@mui/icons-material'
import { useContext } from 'react'
import _ from 'lodash'
import theme from '../../../src/Styles/CoreTheme'
import LogIn from './LogIn'
import { LoginDialogsContext } from '../../context/loginDialogsContext'
import Register from './Register'
import { AuthContext } from '../../context/authContext'
import AuthDialog from './AuthDialog'
import ForgotPasswordDialog from './ForgotPassword'


function CustomAppAppBar() {
  const { currentUser } = useContext(AuthContext);
  const { showClientLogin, setShowClientLogin, showHealerLogin, setShowHealerLogin } = useContext(LoginDialogsContext);
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

  const closeDialog = () => {
    setShowClientLogin(false);
    setShowHealerLogin(false);
  }

  return (
    <div>
      <AppBar position='fixed'>
        <Toolbar>
          <Box sx={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
            <AutoGraph sx={{ mr: 1, mt: isSmDown ? 0.5 : -0.1 }} />
            <Link variant={isSmDown ? 'h6' : 'h3'} underline='none' color='inherit' sx={{ fontSize: 24 }}>
              {'Prana Records'}
            </Link>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
      {/* <Dialog keepMounted={false} open={showClientLogin || showHealerLogin} onClose={closeDialog}> */}
      <Box
        sx={{
          my: 2,
          mt: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minWidth: '100%'
        }}
      >
        <LogIn />
        <Register />
        <AuthDialog />
        <ForgotPasswordDialog />
      </Box>
      {/* </Dialog> */}
    </div>
  )
}

export default CustomAppAppBar
function setFormFields(arg0: any) {
  throw new Error('Function not implemented.')
}
