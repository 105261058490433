import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Avatar, Dialog, Tab, Tabs, useMediaQuery } from '@mui/material';
import { Email } from '@mui/icons-material';
import { useContext, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';
import theme from '../../Styles/CoreTheme';
import axios from 'axios';
import _ from 'lodash';
import { LoginDialogsContext } from '../../context/loginDialogsContext';
import { loginUser } from '../../firebase/firebaseAuth';


// const [loading, setLoading] = React.useState(false)
// const defaultButtonText = 'Send Link'
// const [buttonCopy, setButtonCopy] = React.useState(defaultButtonText)

// const loginSchema = yup.object().shape({
//   email: yup.string().email('Email is Invalid').required('Email Cannot Be Empty'),
// })

// const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//   const value = event.target.value
//   setLoginData({
//     ...loginData,
//     [event.target.id]: value,
//     emailError: ''
//   })
//   if (buttonCopy !== defaultButtonText) {
//     setButtonCopy(defaultButtonText)
//   }
// }

// const handleSendButton = async () => {
//   setLoading(true)
//   const is_dev = process.env.NODE_ENV === 'development';
//   const api_url = is_dev ? process.env.REACT_APP_DEV_API : process.env.REACT_APP_PROD_API;
//   try {
//     await loginSchema.validate({ email: loginData.email });
//     await axios
//       .post(`${api_url}/signin/` || '', { email: loginData.email, ...(is_dev ? { dev_client: true } : {}) })
//       .then(() => setButtonCopy('Sent!  Check your inbox'));
//     setTimeout(() => setButtonCopy('Resend Link'), 10 * 1000);
//   } catch (e: any) {
//     setLoginData({
//       ...loginData,
//       emailError: e.message || 'error'
//     })
//   } finally {
//     setLoading(false)
//   }
// }

const initialButtonCopy = 'LOG IN';

export default function LogIn() {
    const { showClientLogin, setShowClientLogin, showHealerLogin, setShowHealerLogin } = useContext(LoginDialogsContext);
    const [loading, setLoading] = useState(false);
    const defaultButtonText = 'Send Link';
    const [buttonCopy, setButtonCopy] = useState(defaultButtonText);
    const [loginData, setLoginData] = useState({
        email: '',
        emailError: '',
        password: '',
        passwordError: '',
        buttonCopy: initialButtonCopy,
    })

    const loginSchema = yup.object().shape({
        email: yup.string().email('Email is Invalid').required('Email Cannot Be Empty'),
    })

    const handleSubmit = async (event: any) => {
        setLoading(true)

        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
            email: data.get('email'),
            password: data.get('password'),
        });

        const is_dev = process.env.NODE_ENV === 'development';
        const api_url = is_dev ? process.env.REACT_APP_DEV_API : process.env.REACT_APP_PROD_API;
        try {
            await loginSchema.validate({ email: loginData.email });
            await axios
                .post(`${api_url}/login/` || '', { email: loginData.email, ...(is_dev ? { dev_client: true } : {}) })
                .then(() => setButtonCopy('Sent!  Check your inbox'));
            setTimeout(() => setButtonCopy('Resend Link'), 10 * 1000);
            setLoginData({
                ...loginData,
                buttonCopy: 'check your email!'
            });
            setTimeout(() => {
                setLoginData({
                    ...loginData,
                    buttonCopy: initialButtonCopy
                });
            }, 3000);
        } catch (e: any) {
            setLoginData({
                ...loginData,
                emailError: e.message || 'error'
            })
        } finally {
            setLoading(false)
        }

    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        setLoginData({
            ...loginData,
            [event.target.id]: value,
            emailError: ''
        })
        if (buttonCopy !== defaultButtonText) {
            setButtonCopy(defaultButtonText)
        }
    }

    return (
        <Dialog open={showClientLogin} onClose={() => setShowClientLogin(false)}>
            <Box sx={{ m: 'auto' }}>
                <Avatar sx={{ m: 2, bgcolor: 'secondary.main' }}>
                    <Email />
                </Avatar>
                <Typography component='h1' variant='h5' textAlign='center'>
                    Log in
                </Typography>
            </Box>

            <Box sx={{ width: '100%' }}>
                <Container component='main' maxWidth='xs'>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Box component='form' onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            <TextField
                                margin='normal'
                                fullWidth
                                required
                                id='email'
                                label='Email Address'
                                name='email'
                                autoComplete='email'
                                autoFocus
                                value={loginData.email}
                                onChange={handleChange}
                                error={!_.isEmpty(loginData.emailError)}
                                helperText={loginData.emailError ? 'error, ensure email is correct' : ''}
                            />
                            <LoadingButton
                                loading={loading}
                                type='submit'
                                fullWidth
                                variant='contained'
                                color='secondary'
                                sx={{ mt: 3, mb: 2 }}
                            >
                                {loginData.buttonCopy}
                            </LoadingButton>
                        </Box>
                    </Box>
                </Container >
            </Box >
        </Dialog>

    );
}