import React, { SetStateAction, createContext, useEffect, Dispatch, useContext, useState } from 'react'
import { AreaStateType } from '../types'
import { DialogsContext } from './dialogsContext'
import { useAxiosInstance } from '../api/AxiosInstanceContext'

interface AreasStateContext {
  areasState: AreaStateType[]
  areasStateError: string | null
  saveAreaState: (areastateData: any) => void
  savedAreaStateError: string | null
  setSavedAreaStateError: Dispatch<SetStateAction<string | null>>
  deleteAreaState: (stateId: number) => void
}

export const AreasStateContext = createContext<AreasStateContext>(undefined as any)

interface Props {
  children: React.ReactNode
}

export const AreasStateProvider: React.FC<Props> = ({ children }) => {
  const axiosInstance = useAxiosInstance();
  const [areasState, setAreasState] = useState<AreaStateType[]>([] as AreaStateType[]);
  const [areaStateError, setAreaStateError] = useState(null);
  const [savedAreaStateError, setSavedAreaStateError] = useState<string | null>(null);
  const { editSessionDialog } = React.useContext(DialogsContext);

  useEffect(() => {
    if (editSessionDialog && editSessionDialog.currentSession.id) {
      const sessionId = editSessionDialog.currentSession.id
      axiosInstance.post('/areas_states/', JSON.stringify({ session_id: sessionId }))
        .then((response) => {
          setAreasState(response.data);
        }).catch((error) => {
          setAreaStateError(error);
        })
    }
  }, [editSessionDialog.currentSession]);

  const saveAreaState = async (areastateData: any) => {
    axiosInstance.post('/area_state/', JSON.stringify({ ...areastateData }))
      .then((response) => {
        if (areasState) {
          const data = response.data;
          const untracked = areasState.find((state: AreaStateType) => state.id === data.id)
          let newAreasStateArray;
          if (untracked) {
            newAreasStateArray = areasState.map((item: AreaStateType) =>
              item.id === data.id ? data : item,
            )
            setAreasState(newAreasStateArray);
          } else {
            newAreasStateArray = areasState
            newAreasStateArray.push(data)
            setAreasState([...areasState, data]);
          }
        }
      });
  }

  const deleteAreaState = (stateId: number) => {
    axiosInstance.delete('/area_state/', { data: JSON.stringify({ state_id: stateId }) })
      .then(() => {
        const newAreasStateArray = areasState.filter((item: AreaStateType) => {
          item.id !== stateId
        })
        setAreasState(newAreasStateArray);
      });
  }

  const value = {
    areasState: areasState,
    areasStateError: areaStateError,
    saveAreaState,
    savedAreaStateError: savedAreaStateError,
    setSavedAreaStateError,
    deleteAreaState,
  }

  return <AreasStateContext.Provider value={value}>{children}</AreasStateContext.Provider>
}
