import {
  Autocomplete,
  Box,
  Button,
  CssBaseline,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  TextField,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { ClientsContext } from '../../context/clientsContext'
import { CasesContext } from '../../context/casesContext'
import { SessionsContext } from '../../context/sessionsContext'
import { DialogsContext } from '../../context/dialogsContext'
import { log } from 'console'
import { CaseType, User } from '../../types'

export default function CreateSessionDialog() {
  const { clients } = React.useContext(ClientsContext);
  const { cases: clientCases } = React.useContext(CasesContext);
  const [filteredCases, setFilteredCases] = React.useState<CaseType[]>();
  const { saveSession, setSavedSession, savedSession, savedSessionError, getSession } = React.useContext(SessionsContext);
  const { sessionDialogSate, setSessionDialogSate, showSessionDialog, setEditSessionDialog } =
    React.useContext(DialogsContext);

  type AddSessionType = {
    client: User | undefined,
    clientId: number | undefined,
    clientCase: CaseType | undefined,
    clientCaseId: number | undefined,
    date: undefined,
  }

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm<AddSessionType>({
    mode: 'onChange',
    defaultValues: {
      client: undefined,
      clientId: undefined,
      clientCase: undefined,
      clientCaseId: undefined,
      date: undefined,
    },
  })

  const onSubmit = (formInputs: any) => {
    const { clientCase, client, date } = formInputs;

    saveSession({
      case: clientCase.id,
      client: client.id,
      date: date,
    })
      .catch((e) => {
        console.log('create session dialog: ' + e);
      })

  }

  useEffect(() => {
    if (savedSession) {
      const clientCase = clientCases.find((c) => c.id === savedSession.case);
      if (clientCase) {
        setEditSessionDialog((prev) => ({ ...prev, show: true, currentSession: savedSession, sessionCase: clientCase }));
        showSessionDialog({ show: false });
      }
    }
  }, [savedSession]);

  useEffect(() => {
    if (sessionDialogSate) {
      if (sessionDialogSate.clientId) {
        const client = clients.find((c) => c.id === sessionDialogSate.clientId);
        if (client) {
          setValue('client', client);
          filterCases();
        }
      }
      setValue('clientCase', undefined);
    }
  }, [sessionDialogSate]);

  const handleClose = () => {
    showSessionDialog({ show: false });
  }

  // useEffect(() => {
  //     setItemList([
  //         { id: 1, name: 'item1' },
  //         { id: 2, name: 'item2' }
  //     ]);
  //     setValue('case', );
  // }, [setValue]);

  const onChangeHandler = () => {
    const values = getValues();
    if (values && values.client) {
      reset({ ...values, ...{ clientCase: undefined } });
      filterCases();
    }
  }

  const filterCases = () => {
    const selectedClient: User | undefined = getValues().client;
    if (selectedClient) {
      const selectedClientCases = clientCases.filter((clientCase) => {
        if (typeof clientCase.client === 'number') {
          return clientCase.client === selectedClient.id;
        } else {
          return clientCase.client.id === selectedClient.id;
        }
      })
      setFilteredCases(selectedClientCases);
    }
  }

  return (
    <div>
      <Dialog open={!!sessionDialogSate.show} fullWidth keepMounted={false}>
        <CssBaseline />
        <DialogTitle>{'Create New Session'}</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText>
            Make sure to create a new case when doing healing for new symptoms
          </DialogContentText>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <form onSubmit={handleSubmit(onSubmit)} onChange={() => onChangeHandler()} noValidate>
              <Grid container direction={'column'}>
                <Grid item>
                  <Controller
                    control={control}
                    name='client'
                    rules={{ required: true }}
                    render={({ field: { onChange, value, ...field } }) => (
                      <Autocomplete
                        {...field}
                        onChange={(event, client) => {
                          onChange(client)
                          onChangeHandler()
                        }}
                        value={value || null}
                        options={clients ? clients : []}
                        getOptionLabel={(client) => (client.full_name || client.email)}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label='Client'
                            margin='normal'
                            variant='outlined'
                            error={!!errors.client}
                            helperText={errors.client && 'Client required'}
                            required
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Controller
                    control={control}
                    name='clientCase'
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        disabled={!getValues().client}
                        noOptionsText={'Please create a case for this client'}
                        onChange={(event, clientCase) => {
                          onChange(clientCase)
                        }}
                        value={value || null}
                        options={filteredCases || []}
                        getOptionLabel={(clientCase) => (clientCase ? clientCase.label : '')}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label='Case'
                            margin='normal'
                            variant='outlined'
                            error={!!errors.clientCase}
                            helperText={errors.clientCase && 'Case required'}
                            required
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Box m={1} />
                <Grid item>
                  <Controller
                    control={control}
                    name='date'
                    rules={{ required: true }}
                    render={({ field }) => (
                      <DatePicker
                        label='Session Date'
                        onChange={(date) => field.onChange(date)}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            variant: 'outlined',
                            error: !!errors.date,
                            helperText: errors?.date && 'Date required',
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Box m={2} />
                <Grid item>
                  <Button variant='contained' type='submit' color='primary' fullWidth>
                    Create
                  </Button>
                  <Box m={1} />
                  <Button variant='text' onClick={handleClose} color='primary' fullWidth>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </form>
          </LocalizationProvider>
        </DialogContent>
      </Dialog>
    </div>
  )
}
