import { Autocomplete, TextField } from '@mui/material'
import { Box } from '@mui/system'
import { Controller, useForm } from 'react-hook-form';
import { FilterOptionType } from '../types';
import { CasesFiltersType, FiltersContext } from '../context/filtersContetxt';
import React, { useEffect } from 'react';
import { reviewCaseStates, stored } from '../constants';

interface FormValues {
    selectedOptions: FilterOptionType[];
}

export const FilterSelector = ({ options, selections }: { options: FilterOptionType[], selections: CasesFiltersType }) => {

    const { setCasesFilters, casesFilters } = React.useContext(FiltersContext);

    const { control, getValues, setValue } = useForm<FormValues>();

    const updateCasesFilters = () => {
        const states = getValues().selectedOptions.map((o) => o.id.toString());
        const newCasesFilters = { ...casesFilters, ...{ states: states } };
        localStorage.setItem(stored.reviewCasesFilters, JSON.stringify(newCasesFilters));
        setCasesFilters(newCasesFilters);
    };

    useEffect(() => {
        if (selections) {
            const storedStates = [] as FilterOptionType[];
            selections.states.map((fo) => {
                const state = reviewCaseStates.find((rcs) => rcs.id === fo);
                if (state) {
                    storedStates.push(state);
                }
            });
            setValue('selectedOptions', storedStates);
        }
    }, [selections]);

    return (
        <Box sx={{ mr: 1, ml: 1 }}>
            <form>
                <Controller
                    control={control}
                    name='selectedOptions'
                    rules={{ required: true }}
                    render={({ field: { ref, onChange, value, ...field } }) => (
                        <Autocomplete
                            {...field}
                            multiple={true}
                            noOptionsText={'No filters selected'}
                            onChange={(event, values, reason) => {
                                onChange(values);
                                updateCasesFilters();
                            }}
                            value={value || []}
                            options={options}
                            getOptionLabel={(option) => (option.name || 'none')}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label='Case Status Filter'
                                    margin='normal'
                                    variant='standard'
                                />
                            )}
                        />
                    )}
                />
            </form>
        </Box >
    )
}