// src/components/LoadingScreen.tsx
import React, { CSSProperties } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const loadingScreenStyle: CSSProperties = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
};
const LoadingScreen: React.FC = () => {
    return (
        <div style={loadingScreenStyle}>
            <CircularProgress />
        </div>
    );
};

export default LoadingScreen;
