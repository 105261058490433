import * as React from 'react'
import {
  Modal,
  Tabs,
  Tab,
  Box,
  Typography,
  Paper,
  Card,
  Button,
  Badge,
  Stepper,
  Step,
  StepLabel,
  StepButton,
  LinearProgress,
  CircularProgress,
} from '@mui/material'

import { Dispatch, SetStateAction, useContext } from 'react'
import { UserContext } from '../../context/userContext'
import Waivers from '../Waivers'
import { WaiverContext } from '../../context/waiversContext'
import AlertBanner, { AlertBannerType } from '../AlertBanner'
import Welcome from '../Welcome'
import { AuthContext } from '../../context/authContext'
import { LoadingButton } from '@mui/lab'
import _ from 'lodash'
import ProfileForm from '../ProfileData'
import { useFormContext } from '../ProfileData/ProfileFormContext'
import VerifyEmailScreen from '../../routes/LandingPage/VerifyEmail'
import { LargeLogo } from '../LargeLogo'

type ProfileModalType = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const steps = ['Legal', 'Profile', 'Welcome']

const healerTitle = 'Welcome';
const clientTitle = 'Thank You';
const healerCopy = 'Youre ready to get started adding clients, cases and sessions';
const clientCopy = 'Your healer(s) will be notified of accepted waivers and updated information';

const OnbordingModal = (props: ProfileModalType) => {
  const { open, setOpen } = props
  const [value, setValue] = React.useState(0)
  const { areWaiversAccepted, waiverResults, saveWaiversResults, savedWaiversResults, saveWaiversResultsError, waiversTempResults, setWaiversTempResults } = useContext(WaiverContext);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null)
  const { signOut } = useContext(AuthContext);
  const [loading, setLoading] = React.useState(false);
  const [statesLoading, setStatesLoading] = React.useState(true);
  const { onSubmit } = useFormContext();
  const { currentUser } = useContext(AuthContext);
  const { user } = useContext(UserContext)

  function handleClose(event: object, reason: 'backdropClick' | 'escapeKeyDown'): void {
    console.log('attempt to close modal')
    // throw new Error("Function not implemented.");
  }

  interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
  }

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    )
  }

  React.useEffect(() => {
    if (user && waiverResults) {
      setTimeout(() => setStatesLoading(false), 1000);
    }
  }, [user, waiverResults])

  // This runs after saving waiver result
  React.useEffect(() => {
    setLoading(false);
    // if ((waiverResults.length === 0 || savedWaiversResults) && value === 0) {
    //   const notAcceptedArray = savedWaiversResults.map((result) => {
    //     if (result.accepted !== 'Y') {
    //       setErrorMessage('Must read and accept required waivers to proceed');
    //       setLoading(false);
    //     }
    //   });
    //   if (notAcceptedArray.length < 1) {
    //     setLoading(false);
    //     setValue(value + 1);
    //   }
    // }
    // if (saveWaiversResultsError) {
    //   setErrorMessage('Error accepting waivers: ' + saveWaiversResultsError)
    //   setLoading(false);
    // }

  }, [savedWaiversResults, saveWaiversResultsError])

  const getNotAcceptedArr = () => {
    if (!waiverResults) {
      return null;
    }
    return waiverResults.filter((result) => {
      const waiver = waiversTempResults.find((wr) => wr.waiver.id === result.waiver.id);
      if (result.required && waiver && waiver.accepted === 'Y') {
        return false;
      }
      if (!result.required) {
        return false;
      }
      return true;
    });
  };

  const setFalseToNotAcceptedAndNotRequired = () => {
    // const newArr = waiverResults.filter((result) => {
    //   const waiver = waiversTempResults.find((wr) => wr.id === result.id);
    //   console.log('tracker waiver here: ' + JSON.stringify(waiver));
    //   if (waiver) {
    //     return { id: result.id, accepted: waiver.accepted ? waiver.accepted : 'N' };
    //   };
    //   return { id: result.id, accepted: 'N' };
    // });
    // const arr = newArr.filter(res => res !== undefined);
    // console.log('tracker ready to save: ' + JSON.stringify(arr));
    // setWaiversTempResults(arr);
  }

  React.useEffect(() => {
    if (!_.isEmpty(user) && value === 1) {
      setLoading(false);
      setValue(value + 1);
    }
    // if (savedUserError) {
    //   setErrorMessage('Error saving profile: ' + savedUserError)
    //   
    // }
  }, [user])


  const handleNext = async () => {
    const notAcceptedArr = getNotAcceptedArr();
    // console.log('tracker notAcceptedArr: ' + JSON.stringify(notAcceptedArr));
    if (!notAcceptedArr) {
      setErrorMessage('please reload page');
      setLoading(false);
    }

    if (!notAcceptedArr || notAcceptedArr.length > 0) {
      setErrorMessage('must accept required waivers to proceed');
      setLoading(false);
    }
    if (notAcceptedArr && notAcceptedArr.length === 0 && value === 0) {
      setFalseToNotAcceptedAndNotRequired();
      setValue(value + 1)
    } else if (value == 1) {
      onSubmit();
    } else if (value > 1) {
      saveWaiversResults(setLoading);
      if (user.is_healer) {
        setOpen(false)
      } else {
        signOut();
      }
    }
  }


  return (

    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='parent-modal-title'
      aria-describedby='parent-modal-description'
      sx={{ overflow: 'scroll', backgroundColor: 'white' }}
    >
      {
        !statesLoading ?
          (
            <>
              <Card
                elevation={3}
                sx={{
                  margin: 'auto',
                  maxWidth: { xs: 800, lg: 875 },
                  // margin: { xs: 2.5, md: 3 },
                  '& > *': {
                    flexGrow: 1,
                    flexBasis: '50%',
                  },
                  overflow: 'scroll',
                }}
              >

                {!currentUser?.emailVerified ?
                  (
                    <Box mb={4} sx={{ my: 30 }}>
                      <VerifyEmailScreen />
                    </Box>

                  )
                  :
                  (
                    <>
                      <Box sx={{ width: '100%' }}>
                        <Box sx={{ p: 4, borderBottom: 1, borderColor: 'gray' }}>
                          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1, mb: 3 }}>
                            <LargeLogo />
                          </Box>
                          <Stepper activeStep={value}>
                            {steps.map((label, index) => {
                              const stepProps: { completed?: boolean } = {}
                              const labelProps: {
                                optional?: React.ReactNode
                              } = {}
                              // if (isStepOptional(index)) {
                              //     labelProps.optional = (
                              //     <Typography variant="caption">Optional</Typography>
                              //     );
                              // }
                              // if (isStepSkipped(index)) {
                              //     stepProps.completed = false;
                              // }
                              return (
                                <Step key={label}>
                                  <StepButton
                                    color='inherit'
                                    onClick={() => {
                                      if (index < value) {
                                        setValue(index)
                                      }
                                    }}
                                  >
                                    {label}
                                  </StepButton>
                                </Step>
                              )
                            })}
                          </Stepper>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                          {waiverResults === undefined || !waiverResults ? <LinearProgress sx={{ marginTop: 20, marginBottom: 20 }} color='primary' /> : <Waivers />}
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                          <Box my={2}>
                            <Typography variant='h6'>Add or Update Infomation</Typography>
                          </Box>
                          <ProfileForm />
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={2}>
                          <Welcome title={user.is_healer ? healerTitle : clientTitle} copy={user.is_healer ? healerCopy : clientCopy} />
                        </CustomTabPanel>
                      </Box>
                      <Box sx={{ p: 2, width: '100%', minWidth: 275 }}>
                        <LoadingButton loading={loading} variant='contained' fullWidth onClick={handleNext}>
                          {value === 0 ? 'Next' : 'Done'}
                        </LoadingButton>
                      </Box>
                    </>
                  )}


                <Box textAlign='center' marginBottom={2}>
                  <Button onClick={() => signOut()}>Sign out</Button>
                </Box>
                <AlertBanner
                  type={AlertBannerType.error}
                  message={errorMessage}
                  setMessage={setErrorMessage}
                />

              </Card>
            </>
          )
          :
          (
            <Box sx={{ display: 'flex', flex: 1, height: '100vh', backgroundColor: 'whitesmoke' }}>
              <CircularProgress disableShrink sx={{ margin: 'auto' }} />
            </Box>
          )
      }
    </Modal>
  )
}

export default OnbordingModal
