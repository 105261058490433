import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { ClientsContext } from '../../context/clientsContext'
import AlertBanner, { AlertBannerType } from '../AlertBanner'
import { DialogsContext } from '../../context/dialogsContext'
import { LoadingButton } from '@mui/lab'
import { Controller, useForm } from 'react-hook-form'
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Tab,
  Tabs,
  Button,
  Typography,
  Container,
  Grid,
} from '@mui/material';
import { ageOptions, yesNoOptions } from '../../types'


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function AddClientDialog() {
  const { control, handleSubmit } = useForm<FormData>();
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const { addClient, addClientError, clients, setAddClientError } = React.useContext(ClientsContext);
  const [loading, setLoading] = React.useState(false);
  const { setShowAddClientDialog, showAddClientDialog } = React.useContext(DialogsContext);

  const onSubmit = (data: FormData) => {
    setLoading(true);
    addClient({ ...{ untracked: value === 1 }, ...data });
  };

  const handleClose = () => {
    setLoading(false);
    setShowAddClientDialog(false)
  }

  interface FormData {
    firstName: string;
    lastName: string;
    ageRange: string;
    email: string;
    phoneNumber: string;
    possiblyPregnant: string;
    hbpDiagnosed: string;
  }

  // const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const value = event.target.value;
  //   console.log('handling on change: ' + );
  //   setClientData({
  //     ...clientData,
  //     [event.target.id]: value,
  //   })
  // }

  React.useEffect(() => {
    setLoading(false);
    setShowAddClientDialog(false);
  }, [clients])

  React.useEffect(() => {
    setLoading(false);
  }, [addClientError])


  return (
    <Box sx={{ maxWidth: 400 }}>
      <Dialog open={showAddClientDialog} onClose={handleClose} keepMounted={false}>
        <Box sx={{ width: '100%', mt: 2 }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
              <Tab label='Invite Client' sx={{ fontSize: 16 }} {...a11yProps(0)} />
              <Tab label='Manually Add Client' sx={{ fontSize: 16 }} {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <DialogContent>
              <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
                <Grid item xs={12}>
                  <Controller
                    name='email'
                    control={control}
                    defaultValue=''
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label='Email'
                        variant='standard'
                        fullWidth
                        margin='normal'
                      />
                    )}
                  />
                </Grid>
                <Box m={2} />
                <DialogContentText >
                  Client will recieve an email once a case is created for them to approve healing request
                </DialogContentText>
                <Grid item xs={12} sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end', mt: 1 }}>
                  <LoadingButton onClick={handleClose}>Cancel</LoadingButton>
                  <LoadingButton type='submit' loading={loading}>Add</LoadingButton>
                </Grid>
              </form>
            </DialogContent>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <DialogContent>
              {/*               
              <TextField
                id='firstName'
                label='First Name'
                type='email'
                fullWidth
                variant='standard'
                value={clientData.firstName}
                onChange={handleTextChange}
              />
              <Box m={2} />
              <TextField
                margin='dense'
                id='lastName'
                label='Last Name'
                type='email'
                fullWidth
                variant='standard'
                value={clientData.lastName}
                onChange={handleTextChange}
              />
              <Box m={4} />
              <DialogContentText>
                By manually adding a client you take full responsibility for the clients onboarding process,
                including liability waivers.
              </DialogContentText> */}

              <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Controller
                      name='firstName'
                      control={control}
                      defaultValue=''
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label='First Name'
                          variant='standard'
                          fullWidth
                          margin='normal'
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name='lastName'
                      control={control}
                      defaultValue=''
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label='Last Name'
                          variant='standard'
                          fullWidth
                          margin='normal'
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth margin='normal'>
                      <InputLabel id='age-label'>Age</InputLabel>
                      <Controller
                        name='ageRange'
                        control={control}
                        defaultValue=''
                        render={({ field }) => (
                          <Select labelId='age-label' variant='standard' label={'Age'} {...field}>
                            {Object.entries(ageOptions).map(([value, label]) => (
                              <MenuItem key={value} value={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name='phoneNumber'
                      control={control}
                      defaultValue=''
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label='Phone Number'
                          variant='standard'
                          fullWidth
                          margin='normal'
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth margin='normal'>
                      <InputLabel id='possibly-pregnant-label'>Possibly Pregnant</InputLabel>
                      <Controller
                        name='possiblyPregnant'
                        control={control}
                        defaultValue=''
                        render={({ field }) => (
                          <Select labelId='possibly-pregnant-label' variant='standard' label='Possibly Pregnant' {...field}>
                            {Object.entries(yesNoOptions).map(([value, label]) => (
                              <MenuItem key={value} value={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth margin='normal'>
                      <InputLabel id='hbp-diagnosed'>High Blood Pressure Diagnosed</InputLabel>
                      <Controller
                        name='hbpDiagnosed'
                        control={control}
                        defaultValue=''
                        render={({ field }) => (
                          <Select labelId='hbp-diagnosed' variant='standard' label={'High Blood Pressure Diagnosed'} {...field}>
                            {Object.entries(yesNoOptions).map(([value, label]) => (
                              <MenuItem key={value} value={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <DialogContentText>
                      By manually adding a client you take full responsibility for the clients onboarding process,
                      including liability waivers.
                    </DialogContentText>
                  </Grid>
                  <Grid item xs={12} sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                    <LoadingButton onClick={handleClose}>Cancel</LoadingButton>
                    <LoadingButton type='submit' loading={loading}>Add</LoadingButton>
                  </Grid>
                </Grid>
              </form>
            </DialogContent>
          </CustomTabPanel>
        </Box>
      </Dialog>
      <AlertBanner
        type={AlertBannerType.error}
        message={addClientError}
        setMessage={setAddClientError}
      />
    </Box>
  )
}
