import React, { useContext, useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Paper,
    Box,
    Divider,
    Grid,
} from '@mui/material';
import Typography from '../../routes/LandingPage/Typography';
import { DialogsContext } from '../../context/dialogsContext';
import { ReviewSessionsContext } from '../../context/review/ReviewSessionsContext';
import LoadingScreen from '../Loading';
import { ActivationOptions, EnergyLevelOptions, ProtocolType, ageOptions, yesNoOptions } from '../../types';
import { DateTime } from 'luxon';
import { getProtocolLabel } from './EditSessionDialog';
import { ProtocolsContext } from '../../context/protocolsContext';

interface TextItemProps {
    title: string;
    copy: string;
}

const TextItem: React.FC<TextItemProps> = ({ title, copy }) => {

    return (
        <Box>
            <Box sx={{ px: 1 }}>
                <Typography variant='overline'>
                    {title}
                </Typography>
            </Box>
            <Box px={1} sx={{ backgroundColor: 'whitesmoke' }}>
                <Typography>{copy}</Typography>
            </Box>
        </Box>
    );
};


const ViewSessionDialog: React.FC = () => {
    const { viewSessionConfig, setViewSessionConfig } = useContext(DialogsContext);
    const { getReviewSession, reviewSession: rs } = useContext(ReviewSessionsContext);
    const { protocols } = useContext(ProtocolsContext);
    const [majorAreasStates, setMajorAreasStates] = useState([] as any[]);
    const [minorAreasStates, setMinorAreasStates] = useState([] as any[]);

    useEffect(() => {
        if (viewSessionConfig.session && viewSessionConfig.session.id) {
            const sessionId = viewSessionConfig.session.id;
            getReviewSession(sessionId);
        }
    }, [viewSessionConfig])

    const onClose = () => {
        setViewSessionConfig((prev) => ({ ...prev, show: false }));
    }

    const getDisplayDate = () => {
        return DateTime.fromISO(rs.date).toLocaleString(DateTime.DATE_SHORT);
    }

    useEffect(() => {
        if (rs) {
            setMajorAreasStates(rs.areas_states.filter((state: any) => state.type === 'PRE_SESSION' && state.area.major))
            setMinorAreasStates(rs.areas_states.filter((state: any) => state.type === 'PRE_SESSION' && !state.area.major))
        }
    }, [rs])

    const getProtocolsNamesAndRefs = () => {
        const protocolsWithRefs = rs.protocols.map((rsp: ProtocolType) => protocols.find((p) => p.id === rsp.id));
        return protocolsWithRefs.map((p: ProtocolType) => `${getProtocolLabel(p)}`).join(', ') || '--'
    }

    const AreaStates = ({ major }: { major: boolean }) => {
        const states = major ? majorAreasStates : minorAreasStates;
        return (
            <>
                {states.map((s) => {
                    return (
                        <Box key={s.id} >
                            <Box sx={{ backgroundColor: 'whitesmoke' }}>
                                <Typography variant='overline' fontWeight={'bold'} pl={1}>{s.area.name}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Box sx={{ pl: 1 }}>
                                    <Typography variant='overline'>Activation</Typography>
                                </Box>
                                <Box px={1}>
                                    <Typography>{(ActivationOptions[s.activation] || '--').toLowerCase()}</Typography>
                                </Box>
                                <Box sx={{ pl: 1 }}>
                                    <Typography variant='overline'>Energy Level</Typography>
                                </Box>
                                <Box px={1}>
                                    <Typography>{(EnergyLevelOptions[s.energy_level] || '--').toLowerCase()}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    )
                })}
            </>
        )
    }

    // const getProtocols = () => {
    //     return 
    // }

    return (
        <Dialog open={viewSessionConfig.show} onClose={onClose} fullWidth maxWidth='md'>
            {rs ? (
                <>
                    <Box sx={{ backgroundColor: 'grey' }}>
                        <DialogTitle color={'white'}>{`Session ID: ${rs.id.toString()}`}</DialogTitle>
                    </Box>
                    <DialogContent>
                        {/* <Box sx={{ height: 5, width: '100%', backgroundColor: 'grey' }} /> */}
                        <TextItem title='Date' copy={getDisplayDate()} />
                        {/* <TextItem title='Session ID' copy={rs.id.toString()} />
                        <TextItem title='Case ID' copy={rs.case.id} /> */}
                        <TextItem title='Age Range' copy={ageOptions[rs.client.age_range]} />
                        <TextItem title='Possibly Pregnant' copy={yesNoOptions[rs.client.possibly_pregnant]} />
                        <TextItem title='High Blood Pressure Diagnosed' copy={yesNoOptions[rs.client.high_blood_pressure_diagnosed]} />

                        <Box sx={{ height: 23, width: '100%', backgroundColor: 'white' }} />
                        <TextItem title='Previous Session Notes' copy={rs.previous_session_notes || '--'} />
                        <Box sx={{ height: 23, width: '100%', backgroundColor: 'white' }} />

                        <Grid container>
                            <Grid item sm={12} md={6} lg={6}>
                                <Box sx={{ backgroundColor: 'grey', p: 1 }}>
                                    <Typography color={'white'} fontWeight={'bold'}>Major Chakras</Typography>
                                </Box>
                                <AreaStates major={true} />
                            </Grid>
                            <Grid item sm={12} md={6} lg={6}>
                                <Box sx={{ backgroundColor: 'grey', p: 1 }}>
                                    <Typography color={'white'} fontWeight={'bold'}>Minor Chakras</Typography>
                                </Box>
                                <AreaStates major={false} />
                            </Grid>
                        </Grid>
                        <Divider />
                        <TextItem title='Other Observations' copy={rs.pre_session_notes || '--'} />
                        <Box sx={{ height: 23, width: '100%', backgroundColor: 'white' }} />
                        <Box sx={{ backgroundColor: 'grey' }}>
                            <Typography color={'white'} pl={1} fontWeight={'bold'}>Session</Typography>
                        </Box>
                        <TextItem title='Protocols Used' copy={getProtocolsNamesAndRefs()} />
                        <TextItem title='Protocol Notes' copy={rs.protocol_notes || '--'} />
                        <Box sx={{ height: 23, width: '100%', backgroundColor: 'white' }} />
                        <Box sx={{ backgroundColor: 'grey' }}>
                            <Typography color={'white'} pl={1} fontWeight={'bold'}>Post Session Notes</Typography>
                        </Box>
                        <TextItem title='Recommendations' copy={rs.recommendations.map((p: any) => p.name).join(', ') || '--'} />
                        <TextItem title='Post Session Notes' copy={rs.post_session_client_notes || '--'} />


                        {/* {minorAreasStates.map((s) => {
                            return (
                                <Typography key={s.id}>{JSON.stringify(s)}</Typography>
                            )
                        })}
                        {Object.entries(rs).map(([key, value]) => {
                            return (
                                <div key={key}>
                                    <strong>{key}:</strong> {JSON.stringify(value)}
                                </div>
                            )

                        })} */}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color='primary'>
                            Close
                        </Button>
                    </DialogActions>
                </>

            ) : (
                <LoadingScreen />
            )}

        </Dialog>
    );
};

export default ViewSessionDialog;
