import * as React from 'react'
import { ThemeProvider } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import Header from '../componenents/Header'
import theme from '../Styles/CoreTheme'
import { Copyright } from '../componenents/Copyright'
import ReviewCasesTab from '../componenents/Review/ReviewCasesTab'
import Navigator from '../componenents/Navigator'
import EditSessionDialog from '../componenents/SessionsTab/EditSessionDialog'
import ViewSessionDialog from '../componenents/SessionsTab/ViewSessionDialog'
import CaseStatusDialog from '../componenents/CaseStatusDialog'


const drawerWidth = 256

interface TabPanelProps {
    children?: React.ReactNode
    index: number
    tabIndex: number
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, tabIndex, index, ...other } = props

    return (
        <div
            role='tabpanel'
            hidden={tabIndex !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {tabIndex === index && (
                <Box sx={{ minHeight: '88.3vh', py: 6, px: 4, bgcolor: '#eaeff1' }}>{children}</Box>
            )}
        </div>
    )
}

export default function ReviewCore() {
    const [mobileOpen, setMobileOpen] = React.useState(false)
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))
    const [tabIndex, setTabIndex] = React.useState(0)

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen)
    }

    return (
        <ThemeProvider theme={theme}>
            <Box height="100vh" display="flex">
                <CssBaseline />
                <Box component='nav' sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}>
                    {isSmUp ? null : (
                        <Navigator
                            PaperProps={{ style: { width: drawerWidth } }}
                            variant='temporary'
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                        />
                    )}
                    <Navigator
                        PaperProps={{ style: { width: drawerWidth } }}
                        sx={{ display: { sm: 'block', xs: 'none' } }}
                    />
                </Box>
                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <Header
                        onDrawerToggle={handleDrawerToggle}
                        tabIndex={tabIndex}
                        setTabIndex={setTabIndex}
                    />
                    {/* <Box
            component="main"
            sx={{ flex: 1, py: 6, px: 4, bgcolor: "#eaeff1" }}
          >
            <Content />
          </Box> */}
                    {/* <CustomTabPanel tabIndex={tabIndex} index={0}>
                        <ClientsTab />
                    </CustomTabPanel>
                    <CustomTabPanel tabIndex={tabIndex} index={1}>
                        <CasesTab />
                    </CustomTabPanel> */}
                    <CustomTabPanel tabIndex={tabIndex} index={0}>
                        <ReviewCasesTab />
                    </CustomTabPanel>
                    <Box component='footer' sx={{ p: 2, bgcolor: '#eaeff1' }}>
                        <Copyright />
                    </Box>
                </Box>
            </Box>
            <ViewSessionDialog />
            <CaseStatusDialog />
        </ThemeProvider>
    )
}
