import * as React from 'react'
import { Modal, Box, Card, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Divider, useMediaQuery } from '@mui/material'

import { Dispatch, SetStateAction, useContext } from 'react'
import { UserContext } from '../../context/userContext'
import { CaseStatusType } from '../../types';
import Typography from '../../routes/LandingPage/Typography';
import { CheckCircle, CheckCircleOutline, Close, DoNotDisturbOn, Feedback, FeedbackOutlined, Forward, Inventory, NotificationAdd, NotificationsActive, Publish, Visibility } from '@mui/icons-material';
import { CasesContext } from '../../context/casesContext';
import { ClientsContext } from '../../context/clientsContext';
import { DateTime } from 'luxon';
import { theme } from '../SessionsTab/AreaStateSetterStyle';

interface CustomModalType {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
}

const CustomModal = (props: CustomModalType) => {
    const { open, setOpen } = props
    const { displayCaseStatuses: statuses, cases } = useContext(CasesContext);
    const { clients } = useContext(ClientsContext);
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));



    function handleClose(event: object, reason: 'backdropClick' | 'escapeKeyDown'): void {
        console.log('attempt to close modal')
        // throw new Error("Function not implemented.");
    }

    interface TabPanelProps {
        children?: React.ReactNode
        index: number
        value: number
    }

    const getCaseFromId = (caseId: number) => {
        return cases.find((c) => c.id === caseId)
    }

    const getClientFromId = (clientId: number) => {
        return clients.find((c) => c.id === clientId)
    }

    const getLineClientName = (item: CaseStatusType) => {
        const itemCase = getCaseFromId(item.case_id);
        const itemClient = getClientFromId(itemCase?.client as number);
        return `${itemClient?.full_name}`
    }

    const getLineCaseName = (item: CaseStatusType) => getCaseFromId(item.case_id)?.name;

    React.useEffect(() => {
        console.log('tracker: ', JSON.stringify(statuses));
    });


    const getLineItemIcon = (state: string) => {
        let icon = <NotificationsActive sx={{ mr: 1 }} />;


        let text = 'Event';
        switch (state.toLowerCase()) {
            case 'approved':
                icon = <CheckCircle sx={{ color: 'green', mr: 1 }} />;
                text = 'APPROVED'
                break;
            case 'info_requested':
                icon = <Feedback sx={{ color: 'orange', mr: 1 }} />;
                text = 'INFORMATION REQUESTED'
                break;
            case 'denied':
                icon = <DoNotDisturbOn sx={{ color: 'red', mr: 1 }} />;
                text = 'DENIED'
                break;
            case 'submitted':
                icon = <Inventory sx={{ color: 'blueviolet', mr: 1 }} />;
                text = 'SUBMITTED'
                break;
        }
        return (
            <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center', justifyItems: 'center' }}>
                {icon}
                <Typography sx={{ pt: 0.6 }}>{text}</Typography>
            </Box>
        )
    }

    const getLineDate = (item: CaseStatusType) => {
        return DateTime.fromISO(item.created_date).toLocaleString(DateTime.DATE_SHORT);
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogActions>
                <Box onClick={() => setOpen(false)}>
                    <Close color="primary" />
                </Box>
            </DialogActions>
            <DialogTitle>
                <Typography variant={isSmUp ? 'h5' : 'h6'}>Case Review Updates</Typography>
            </DialogTitle>
            <Divider />
            <DialogContent sx={isSmUp ? { minWidth: 600 } : { minWidth: 300 }}>
                <DialogContentText>
                    {/* Display the list of items */}
                    {statuses ? statuses.filter((s) => s.state !== 'UNSUBMITTED').map((item, index) => (
                        <>
                            <Box key={item.id} sx={{ display: 'flex', flexDirection: 'column', marginY: 2 }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    {getLineItemIcon(item.state)}
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: isSmUp ? 'row' : 'column' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', flex: 3 }}>
                                        <Typography variant='overline' sx={{ textAlign: 'left' }}>client</Typography>
                                        <Typography >{getLineClientName(item)}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', flex: 3 }}>
                                        <Typography variant='overline' sx={{ textAlign: 'left' }}>case</Typography>
                                        <Typography >{getLineCaseName(item)}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', flex: 3 }}>
                                        <Typography variant='overline' sx={{ textAlign: 'left' }}>date</Typography>
                                        <Typography >{getLineDate(item)}</Typography>
                                    </Box>
                                </Box>

                            </Box>
                            {statuses.length !== index + 1 && <Divider />}
                        </>

                    )) : <Typography>No Case Updates</Typography>}
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}

export default CustomModal
