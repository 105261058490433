import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Box, LinearProgress } from '@mui/material'
import { SearchContext } from '../../../context/searchContext'
import { ReviewCasesContext } from '../../../context/review/ReviewCasesContext'
import { ReviewSessionsContext } from '../../../context/review/ReviewSessionsContext'
import ReviewCasesTable from './ReviewCasesTable'
import _ from 'lodash'
import { FilterSelector } from '../../FilterSelector'
import { reviewCaseStates } from '../../../constants'
import { FiltersContext } from '../../../context/filtersContetxt'
import { User } from '../../../types'

const ReviewCasesTab = () => {
    const { reviewCases, refreshReviewCases, filteredCases, setFilteredCases, loadingReviewCases } = React.useContext(ReviewCasesContext);
    const { refreshReviewSessions } = React.useContext(ReviewSessionsContext);
    const { casesFilters } = React.useContext(FiltersContext);
    const { caseInfoSubstring, setCaseInfoSubstring } = React.useContext(SearchContext);

    React.useEffect(() => {
        if (reviewCases && caseInfoSubstring && !_.isEmpty(caseInfoSubstring)) {
            if (caseInfoSubstring.length < 1) {
                setFilteredCases(undefined);
            } else {
                const result = reviewCases.filter((c) => {
                    const id = c.id.toString();
                    const name = c.name.toLowerCase();
                    const healer_name = (c.healer as User).full_name;
                    const healerMatch = healer_name.toLowerCase().includes(caseInfoSubstring.toLowerCase());
                    const statuses = c.statuses.filter((s) => !['UNSUBMITTED', 'SUBMITTED'].includes(s.state));
                    const allReviewers = statuses.map((s) => (s.setter as User));
                    const uniqueReviewers = _.unionBy(allReviewers, 'id')
                    const reviewerMatch = uniqueReviewers.some(r => r.full_name.toLowerCase().includes(caseInfoSubstring.toLowerCase()));
                    const nameMatch = name.toLowerCase().includes(caseInfoSubstring.toLowerCase());
                    const idMatch = id.includes(caseInfoSubstring.toLowerCase());
                    return nameMatch || idMatch || reviewerMatch || healerMatch;
                });
                setFilteredCases(result);
            }
        }
    }, [caseInfoSubstring, reviewCases]);


    const getReviewCasesTable = () => {
        if (!reviewCases) {
            return <Box sx={{ margin: 10, marginTop: 20, marginBottom: 20 }}> <LinearProgress /></Box >;
        } else if (reviewCases.length < 1) {
            return (
                <Box m={5}>
                    <Typography sx={{ margin: 'auto' }} color='text.secondary' align='center'>
                        No Cases Yet
                    </Typography>
                </Box>)
        } else {
            return <ReviewCasesTable />;
        }
    }

    const refreshReviewCoreData = () => {
        refreshReviewCases();
        refreshReviewSessions();
    }

    const casesDisplayed = () => caseInfoSubstring && filteredCases ? filteredCases : reviewCases;

    return (
        <>
            <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }}>
                <AppBar
                    position='static'
                    color='default'
                    elevation={0}
                    sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
                >
                    <Toolbar>
                        <Grid container spacing={2} alignItems='center' direction={'row'}>
                            <Grid item>
                                <SearchIcon color='inherit' sx={{ display: 'block' }} />
                            </Grid>
                            <Grid item xs >
                                <TextField
                                    value={caseInfoSubstring}
                                    fullWidth
                                    placeholder='Search Cases'
                                    InputProps={{
                                        disableUnderline: true,
                                        sx: { fontSize: 'default' },
                                    }}
                                    variant='standard'
                                    onChange={(e) => { setCaseInfoSubstring(e.target.value) }}
                                />
                            </Grid>
                            <Grid item xs container justifyContent='flex-end'>
                                <Grid item sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <Tooltip title='Reload' onClick={() => refreshReviewCoreData()}>
                                        <IconButton>
                                            <RefreshIcon color='inherit' sx={{ display: 'block' }} />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </Paper>
            <Box sx={{ maxWidth: 936, overflow: 'hidden', m: 'auto', mb: -2.3 }}>
                <FilterSelector options={reviewCaseStates} selections={casesFilters} />
            </Box>
            <Box sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }}>
                <LinearProgress sx={{ m: 1, opacity: loadingReviewCases ? 1 : 0 }} />
                <Typography variant='overline' sx={{ m: 1, color: 'gray' }}>{`${casesDisplayed().length} Cases Displayed`}</Typography>
            </Box>
            <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }} elevation={1}>
                {getReviewCasesTable()}
            </Paper>
        </>
    )
}

export default ReviewCasesTab;
