import React, { Dispatch, SetStateAction, createContext, useContext, useEffect, useMemo, useState } from 'react'
import { User } from '../types'
import { useAxiosInstance } from '../api/AxiosInstanceContext'
import { WaiverContext } from './waiversContext';

type UpdateClientType = {
  clientId: number,
  clientEmail: string,
}

interface ClientsContext {
  clients: User[];
  clientsError: string | null
  addClient: ({
    untracked,
    email,
    firstName,
    lastName,
    ageRange,
    phoneNumber,
    possiblyPregnant,
    hbpDiagnosed
  }: {
    untracked: boolean
    email: string
    firstName: string
    lastName: string
    ageRange: string;
    phoneNumber: string;
    possiblyPregnant: string;
    hbpDiagnosed: string;
  }) => void
  addClientError: string | null
  setAddClientError: Dispatch<SetStateAction<string | null>>
  removeClientError: string | null
  setRemoveClientError: Dispatch<SetStateAction<string | null>>
  getClient: (clientId: User | number) => User | undefined;
  refreshClients: () => void;
  resendWaiver: (caseId: number) => Promise<boolean>;
  updateClient: (updateClient: UpdateClientType) => void;
  removeClient: (client_id: number) => Promise<boolean>;
}

export const ClientsContext = createContext<ClientsContext>(undefined as any)

interface Props {
  children: React.ReactNode
}

export const ClientsProvider: React.FC<Props> = ({ children }) => {
  const axiosInstance = useAxiosInstance();
  const { waiverResults } = useContext(WaiverContext);
  const [clients, setClients] = useState<User[]>([] as User[]);
  const [clientsError, setClientsError] = useState<string | null>(null);
  const [addClientError, setAddClientError] = useState<string | null>(null);
  const [removeClientError, setRemoveClientError] = useState<string | null>(null);

  useEffect(() => {
    axiosInstance.get('/clients')
      .then((response) => setClients(response.data.clients))
      .catch((e) => setClientsError(e));
  }, []);


  useEffect(() => {
    if (waiverResults) {
      axiosInstance.get('/clients')
        .then((response) => setClients(response.data.clients))
        .catch((e) => setClientsError(e));
    }
  }, [waiverResults]);

  const refreshClients = () => {
    axiosInstance.get('/clients')
      .then((response) => setClients(response.data.clients))
      .catch((e) => setClientsError(e));
  };

  const addClient = async ({
    untracked,
    email,
    firstName,
    lastName,
    ageRange,
    phoneNumber,
    possiblyPregnant,
    hbpDiagnosed,
  }: {
    untracked?: boolean;
    email?: string;
    firstName?: string;
    lastName?: string;
    ageRange: string;
    phoneNumber: string;
    possiblyPregnant: string;
    hbpDiagnosed: string;
  }) => {
    let body = {}
    if (untracked) {
      body = {
        untracked_client: true,
        client_first_name: firstName,
        client_last_name: lastName,
        age_range: ageRange,
        phone_number: phoneNumber,
        possibly_pregnant: possiblyPregnant,
        hb_pressure_diagnosed: hbpDiagnosed
      }
    } else {
      body = {
        untracked_client: false,
        client_email: email,
      }
    }
    try {
      const { data } = await axiosInstance.put('/client/', JSON.stringify(body));
      if (data && Object.keys(data).length > 0) {
        setClients(data.clients)
      }
    } catch (e) {
      console.warn('addClient error: ' + e)
    }
  };

  const getClient = (client: User | number) => {
    if (client && clients && clients !== undefined) {
      if (typeof client !== 'number') {
        return clients.find((c: User) => c.id === client.id);
      } else {
        return clients.find((c: User) => c.id === client);
      }
    }
  };

  const updateClient = async ({ clientId, clientEmail }: UpdateClientType) => {
    const body = {
      client_id: clientId,
      client_email: clientEmail
    }
    await axiosInstance.post('/update_client/', JSON.stringify(body))
      .then((res) => {
        setClients(clients.map((c) => c.id === res.data.id ? res.data : c))
      })
      .catch((e) => 'updateClient error: ' + e);

  }

  const removeClient = async (clientId: number) => {
    try {
      const updateClientData = {
        client_id: clientId
      }
      await axiosInstance.post('/remove_client/', JSON.stringify(updateClientData));
    } catch (e) {
      console.log('removeClient error: ' + e)
      return false;
    }
    return true;
  }

  const resendWaiver = async (caseId: number) => {
    try {
      await axiosInstance.post('/resend_case_waiver/', JSON.stringify({ case_id: caseId }));
      return true;
    } catch (e) {
      return false;
    }
  }

  const values = {
    clients,
    clientsError,
    addClient,
    addClientError,
    setAddClientError,
    removeClient,
    removeClientError,
    setRemoveClientError,
    getClient,
    refreshClients,
    resendWaiver,
    updateClient,
  };

  return <ClientsContext.Provider value={values}>{children}</ClientsContext.Provider>;
}

