import React, { Dispatch, SetStateAction, createContext, useContext, useEffect, useState } from 'react'
import { CaseType } from '../../types'
import { useAxiosInstance } from '../../api/AxiosInstanceContext';
import { FiltersContext } from '../filtersContetxt';
import { stored } from '../../constants';


interface ReviewCasesContext {
    reviewCases: CaseType[];
    loadingReviewCases: boolean;
    setLoadingReviewCases: Dispatch<SetStateAction<boolean>>;
    reviewCasesError: string | null;
    refreshReviewCases: () => void;
    addReviewCaseStatus: (caseId: number, status: string, notes: string) => void;
    // saveReviewCase: ({ description, reviewers }: { description?: string | undefined; reviewers?: number[] | undefined; }) => void;
    filteredCases: CaseType[] | undefined;
    setFilteredCases: Dispatch<SetStateAction<CaseType[] | undefined>>;
}

export const ReviewCasesContext = createContext<ReviewCasesContext>(undefined as any)

interface Props {
    children: React.ReactNode
}

export const ReviewCasesProvider: React.FC<Props> = ({ children }) => {
    const axiosInstance = useAxiosInstance();
    const [reviewCases, setReviewCases] = useState([] as CaseType[]);
    const [loadingReviewCases, setLoadingReviewCases] = useState(false);
    const [reviewCasesError, setReviewCasesError] = useState<string | null>(null);
    const [setAddCaseStatus, setAddCaseStatusError] = useState<string | null>(null);
    const [filteredCases, setFilteredCases] = useState<CaseType[] | undefined>();
    const { casesFilters } = useContext(FiltersContext);
    // const [savedReviewCase, setSavedReviewCase] = useState();
    // const [savedReviewCaseError, setSavedReviewCaseError] = useState();

    const getReviewCases = () => {
        const reviewCasesFilters = localStorage.getItem(stored.reviewCasesFilters);
        setLoadingReviewCases(true);
        axiosInstance.post('/review_cases/', reviewCasesFilters)
            .then(({ data }) => {
                setReviewCases(data)
            })
            .catch((e) => {
                console.error('get review cases error:', e);
                setReviewCasesError(e);
            }).finally(() => setLoadingReviewCases(false));
    };

    useEffect(() => {
        getReviewCases();
    }, [casesFilters]);

    const refreshReviewCases = () => {
        getReviewCases();
    };

    const addReviewCaseStatus = async (caseId: number, status: string, notes: string) => {
        await axiosInstance.post(
            '/review_add_case_status/',
            JSON.stringify({
                case_id: caseId,
                state: status,
                notes: notes,
            }))
            .then(({ data }) => {
                const updatedCases = reviewCases.map((c) => {
                    if (c.id === caseId) {
                        c.statuses.push(data)
                    }
                    return c;
                });
                setReviewCases(updatedCases);
            }).catch((e) => setAddCaseStatusError(e));
    };

    const values = {
        reviewCases: reviewCases ? reviewCases : [],
        loadingReviewCases,
        setLoadingReviewCases,
        reviewCasesError,
        refreshReviewCases,
        addReviewCaseStatus,
        filteredCases,
        setFilteredCases
    };

    return <ReviewCasesContext.Provider value={values}>{children}</ReviewCasesContext.Provider>;
}
