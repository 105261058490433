import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, FormControlLabel, Checkbox, Typography } from '@mui/material';
import { useForm, Controller, useController } from 'react-hook-form';

interface WaiverCheckboxDialogProps {
    title: string;
    copy: string;
    label: string;
    name: string;
    formMethods: any; // You can define a proper type for this
    required: boolean;
}

const WaiverCheckboxDialog: React.FC<WaiverCheckboxDialogProps> = ({ title, copy, label, name, formMethods, required }) => {
    const { control, setValue, getValues } = formMethods;
    const [open, setOpen] = useState(false);

    const handleDialogOpen = (event: React.MouseEvent) => {
        event?.preventDefault();
        if (!getValues()[name]) {
            setOpen(true);
        }
        setValue(name, false);
    };

    const handleDialogClose = (event: React.MouseEvent) => {
        event?.preventDefault();
        setOpen(false);
    };

    const handleAccept = (event: React.MouseEvent) => {
        event?.preventDefault();
        setValue(name, true);
        setOpen(false);
    };

    return (
        <div>
            <FormControlLabel
                control={
                    <Controller
                        name={name}
                        control={control}
                        defaultValue={false}
                        render={({ field: { value, ref, ...field } }) => (
                            <Checkbox
                                {...field}
                                inputRef={ref}
                                checked={!!value}
                                color='primary'
                                size={'medium'}
                                disableRipple
                                style={{ paddingTop: 5 }}
                                required={required}
                            />
                        )}
                    />
                }
                label={label}
                labelPlacement='end'
                onClick={handleDialogOpen}
            />
            <Dialog
                keepMounted={false}
                open={open}
                onClose={handleDialogClose}
                scroll={'paper'}
                aria-labelledby='scroll-dialog-title'
                aria-describedby='scroll-dialog-description'>
                <DialogTitle id='scroll-dialog-title'>{title}</DialogTitle>
                <DialogContent dividers={true}>
                    <Typography variant='body1' component='pre' sx={{ whiteSpace: 'pre-wrap' }}>
                        {copy}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color='primary'>
                        Close
                    </Button>
                    <Button onClick={handleAccept} color='primary'>
                        Accept
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default WaiverCheckboxDialog;
