import { initializeApp } from 'firebase/app'
import {
  getAuth,
  onAuthStateChanged,
  signOut,
  NextOrObserver,
  User,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  isSignInWithEmailLink,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
} from 'firebase/auth'
import { getFirebaseConfig } from './firebaseConfig'
import { setItem } from '../utils/storage'

const app = initializeApp(getFirebaseConfig())
const auth = getAuth(app)

export const singInWithLink = () => {
  if (isSignInWithEmailLink(auth, window.location.href)) {
    const link = window.location.href
    const email = link.substring(link.indexOf('?email=') + 7, link.lastIndexOf('&apiKey='))
    signInWithEmailLink(auth, email, window.location.href)
      .then((result) => {
        window.localStorage.removeItem('logInEmail')
      })
      .catch((error) => {
        console.log('loginInWithEmailLink error: ' + error)
      })
  }
}

export const sendLoginInLink = async (email: string) => {
  setItem('logInEmail', email)
  const clientUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_CLIENT_URL : process.env.REACT_APP_PROD_CLIENT_URL;

  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: clientUrl || '',
    // This must be true.
    handleCodeInApp: true,
  }
  await sendSignInLinkToEmail(auth, email, actionCodeSettings)
}

export const registerUser = async (email: string, password: string) => {
  if (!email && !password) return
  return await createUserWithEmailAndPassword(auth, email, password)
}

const getHostUrl = () => {
  const localUrl = 'http://localhost:3000'
  const prodUrl = 'https://www.pranarecords.app'
  if (process.env.NODE_ENV === 'development') {
    console.log('host:' + process.env.REACT_APP_PROD_HOST)
    return localUrl;
  } else {
    console.log('host: ' + process.env.REACT_APP_PROD_HOST)
    return prodUrl;
  }
}

export const verifyEmail = async () => {
  const host = getHostUrl();
  const currentUser = auth.currentUser
  const actionCodeSettings = {
    url: `${host}/login`,
    handleCodeInApp: false,
  }
  if (currentUser) {
    return sendEmailVerification(auth.currentUser, actionCodeSettings)
  }
  throw new Error('could not send verification email')
}

export const loginUser = async (email: string, password: string) => {
  if (!email && !password) {
    throw new Error('problem with login or password')
  }

  return await signInWithEmailAndPassword(auth, email, password)
}

export const resetPassword = (email: string) => {
  const host = getHostUrl();
  const actionCodeSettings = {
    url: host || '',
    handleCodeInApp: false,
  }
  sendPasswordResetEmail(auth, email, actionCodeSettings)
    .then((res) => {
      console.log('sendPasswordResetEmail res: ' + JSON.stringify(res));
    })
    .catch((e) => {
      console.log('sendPasswordResetEmail e: ' + JSON.stringify(e));
    })
}

export const userStateListener = (callback: NextOrObserver<User>) => {
  return onAuthStateChanged(auth, callback)
}

export const signOutUser = async () => {
  return await signOut(auth)
}

export const getFirebaseIdToken = async (refresh = false) => {
  return auth.currentUser?.getIdToken(refresh)
}

const getCurrentUser = () => {
  return auth.currentUser
}
export const currentUser = getCurrentUser()
