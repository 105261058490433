export type User = {
  id: number
  first_name: string
  last_name: string
  full_name: string
  age_range: string
  email: string
  country: string
  state: string
  phone_number: string
  onboarded: boolean
  tracked: boolean
  label: string
  anon_label: string
  is_admin: boolean
  is_case_reviewer: boolean
  is_healer: boolean
  is_client: boolean
  waivers_results: WaiverResult[],
  possibly_pregnant: string | null
  // high blood pressure
  high_blood_pressure_diagnosed: string | null
}

export const FakeUser: User = {
  id: 0,
  first_name: 'first name',
  last_name: 'last name',
  full_name: 'full name',
  email: 'email',
  country: 'country',
  state: 'state',
  phone_number: 'phone',
  age_range: '',
  onboarded: false,
  tracked: false,
  label: 'label',
  anon_label: '',
  is_admin: false,
  is_case_reviewer: false,
  is_healer: false,
  is_client: false,
  waivers_results: [],
  possibly_pregnant: null,
  high_blood_pressure_diagnosed: null
}

export type WaiverType = {
  id?: number
  title: string
  copy: string
}

export type TempWaiverResultType = {
  id: number;
  accepted: string;
}

export type WaiverResult = {
  id: number
  profile: string
  email_record: string
  ip_record: string
  waiver: WaiverType
  waiver_id: number
  accepted: string | null
  required: boolean
  response_on: string
  // case_name: string;
  case_id: number;
  related_profile_id: number;
  related_profile_name: string;
  related_profile_email: string;
}

export type Book = {
  id: number;
  title: string;
  isbn: string;
}

export type Entity = {
  first_name: string;
  last_name: string;
  start_date: string;
  end_date: string;
  description: string;
}

export type ProtocolReference = {
  id: number
  string_id: string;
  book: Book;
  page_number: string;
  url: string;
  entity: Entity;
  description: string;
  // protocol: number;
}

export type ProtocolType = {
  id: number
  name: string
  description: string
  areas: number[]
  reference: ProtocolReference
  label: string
}

export type RecommendationType = {
  id: number
  name: string
  description: string
  label: string
}

export type CreatedProtocolType = {
  id: number
  name: string
  description: string
  areas: number[]
  created_areas: number[]
  created_by: number
}

export type AreaType = {
  id: number
  name: string
  description: string
  delicate: boolean
  major: boolean
}

export type CreatedAreaType = {
  id: number
  name: string
  description: string
  delicate: boolean
  created_by: number
}

export type AreaStateType = {
  id: number
  type: string
  area: AreaType
  created_area: number
  activation: number
  energy_level: number
  session: number
}

export type SessionType = {
  id: number
  case: number
  healer: number
  client: number
  date: string
  previous_session_notes: string
  pre_session_notes: string
  protocol_notes: string
  post_session_client_notes: string
  protocols: number[]
  recommendations: number[]
  created_protocols: number[]
  areas_states: AreaStateType[]
  client_reviewed_on: string | null
  remote: string | null | undefined
}

export type CaseStatusType = {
  id: number
  state: string
  setter: User | number
  notes: string
  created_date: string
  case_id: number
}

export type CaseCategoryType = {
  id: number
  name: string
}

export type CaseType = {
  id: number
  name: string
  healer: User | number
  client: User | number
  type: string
  created_date: string
  label: string
  statuses: CaseStatusType[]
  reviewers: User[] | number[]
  review_state_description: string
  purpose: string
  remote: string
  categories: CaseCategoryType[]
}

export type CaseMessageType = {
  id: number
  author: User | number
  message: string
  creted_date: string
}

export const FormFieldType = {
  TEXT_FIELD: 'TEXT_FIELD',
  DROPDOWN: 'DROPDOWN',
  CHECKBOX: 'CHECKBOX',
}

interface AgeOptions {
  [key: string]: string;
}


export const ageOptions: AgeOptions = {
  'LESS_THAN_2_M': 'less than 2 months old',
  'LESS_THAN_1_Y': 'less than 1 year old',
  '1_TO_7_Y': '1 to 7 years old',
  '8_TO_14_Y': '8 to 14 years old',
  '15_TO_20_Y': '15 to 20 years old',
  '20_S': '20s',
  '30_S': '30s',
  '40_S': '40s',
  '50_S': '50s',
  '60_S': '60s',
  '70_S': '70s',
  '80_PLUS': '80+'
}

interface YesNoOptons {
  [key: string]: string;
}

export const yesNoOptions: YesNoOptons = {
  'NO_ANSWER': 'No Answer',
  'Y': 'Yes',
  'N': 'No',
}

interface RemoteOptions {
  [key: string]: string;
}

export const remoteOptions: RemoteOptions = {
  'NO_ANSWER': 'No Answer',
  'IN_PERSON': 'In Person',
  'REMOTE': 'emote'
}

interface EnergyLevelOptions {
  [key: string]: string;
}

export const EnergyLevelOptions: YesNoOptons = {
  '0': 'Not Scanned',
  '6': 'Underactivated',
  '12': 'Normal',
  '18': 'Overactivated'
}

interface ActivationOptions {
  [key: string]: string;
}

export const ActivationOptions: YesNoOptons = {
  '0': 'Not Scanned',
  '6': 'Depleted',
  '12': 'Normal',
  '18': 'Congested'
}

export type CaseTypeType = { id: number, label: string };

export const typeOptions: CaseTypeType[] = [
  { id: 1, label: 'Psychological' },
  { id: 2, label: 'Complex' },
  { id: 3, label: 'Simple' },
]

export type FilterOptionType = {
  id: string;
  name: string | null;
}




export type Clients = User[]