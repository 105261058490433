import React, { SetStateAction, createContext, useEffect, Dispatch, useState } from 'react'
import { CreatedProtocolType } from '../types'
import { useAxiosInstance } from '../api/AxiosInstanceContext'

interface CreatedProtocolsContext {
  createdProtocols: CreatedProtocolType[]
  createdProtocolsError: string | null
  saveCreatedProtocol: (createdProtocol: Partial<CreatedProtocolType>) => void
  saveCreatedProtocolError: string | null
  setSaveCreatedProtocolError: Dispatch<SetStateAction<string | null>>
}

export const CreatedProtocolsContext = createContext<CreatedProtocolsContext>(undefined as any)

interface Props {
  children: React.ReactNode
}

export const CreatedProtocolsProvider: React.FC<Props> = ({ children }) => {
  const axiosInstance = useAxiosInstance();
  const [data, setData] = useState<CreatedProtocolType[] | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [savedCreatedProtocol, setSavedCreatedProtocol] = useState<CreatedProtocolType | null>(null);
  const [saveCreatedProtocolError, setSaveCreatedProtocolError] = useState<string | null>(null);

  useEffect(() => {
    axiosInstance.get('/created_areas')
      .then((response) => {
        setData(response.data)
      }).catch((e) => setError(e));
  }, []);

  useEffect(() => {
    if (savedCreatedProtocol && data) {
      const newCreatedProtocols = data.map((result: CreatedProtocolType) =>
        result.id === savedCreatedProtocol.id ? savedCreatedProtocol : result,
      );
      setData(newCreatedProtocols);
    }
  }, [savedCreatedProtocol]);

  const saveCreatedProtocol = (createdProtocol: Partial<CreatedProtocolType>) => {
    if (createdProtocol) {
      axiosInstance.put('/created_protocol/', JSON.stringify(createdProtocol))
        .then((response) => setSavedCreatedProtocol(response.data))
        .catch((e) => setSaveCreatedProtocolError(e));
    }
  }

  const value = {
    createdProtocols: data ? (data as CreatedProtocolType[]) : ([] as CreatedProtocolType[]),
    createdProtocolsError: error,
    saveCreatedProtocol,
    saveCreatedProtocolError,
    setSaveCreatedProtocolError,
  }

  return (
    <CreatedProtocolsContext.Provider value={value}>{children}</CreatedProtocolsContext.Provider>
  )
}
