import { Dialog, DialogTitle, DialogContent, FormControl, InputLabel, Select, MenuItem, TextField, DialogActions, Button, makeStyles, Box, Autocomplete, useMediaQuery, Alert } from '@mui/material';
import { useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { DialogsContext } from '../../context/dialogsContext';
import { theme } from '../SessionsTab/AreaStateSetterStyle';
import { ReviewCasesContext } from '../../context/review/ReviewCasesContext';


const CaseStatusDialog: React.FC = () => {
    const { caseStatusDialog, setCaseStatusDialog } = useContext(DialogsContext);
    const { addReviewCaseStatus, refreshReviewCases } = useContext(ReviewCasesContext);
    const { getValues, control, handleSubmit, reset } = useForm();
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));


    const onSubmit = () => {
        const { status, notes } = getValues();
        if (caseStatusDialog && caseStatusDialog.case) {
            addReviewCaseStatus(caseStatusDialog.case.id, status.value, notes)
            reset();
            refreshReviewCases()
            setCaseStatusDialog((prev) => ({ ...prev, show: false }));
        }
    };

    const statesOptions = [
        { value: 'UNSUBMITTED', label: 'Unsubmitted' },
        { value: 'SUBMITTED', label: 'Submitted' },
        { value: 'IN_REVIEW', label: 'In Review' },
        { value: 'INFO_REQUESTED', label: 'Information Requested' },
        { value: 'DENIED', label: 'Denied' },
        { value: 'APPROVED', label: 'Approved' },
        { value: 'ESCALATED', label: 'Escalated' },
        { value: 'ESCALATION_RESPONSE', label: 'Escalation Response' }
    ]

    return (
        <Dialog open={!!caseStatusDialog.show} onClose={() => setCaseStatusDialog((prev) => ({ ...prev, show: false }))}>
            <Box sx={{ width: isSmDown ? '100%' : 600 }}>
                <DialogTitle>Add Case Status</DialogTitle>
                <DialogContent >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Controller
                            control={control}
                            name='status'
                            render={({ field: { ref, onChange, value, ...field } }) => (
                                <Autocomplete
                                    {...field}
                                    onChange={(event, values, reason) => onChange(values)}
                                    value={value || []}
                                    options={statesOptions}
                                    getOptionLabel={(option) => option.label || ''}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            inputRef={ref}
                                            label='Status'
                                            margin='normal'
                                            variant='outlined'
                                        // error={!!errors.session_protocols}
                                        // helperText={errors.session_protocols && 'Protocol required'}
                                        />
                                    )}
                                />
                            )}
                        />
                        <Box m={2} />
                        <Controller
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label={'Status Notes'}
                                    fullWidth
                                    multiline
                                    minRows={10}
                                    maxRows={20}
                                />
                            )}
                            name='notes'
                            control={control}
                        />
                        <Box m={2} />
                        <DialogActions>
                            <Button type='submit' variant='contained' color='primary'>
                                Submit
                            </Button>
                            <Button onClick={() => setCaseStatusDialog((prev) => ({ ...prev, show: false }))} color='info'>
                                Cancel
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Box>

        </Dialog>
    );
};

export default CaseStatusDialog;
