import * as React from 'react'
import { ThemeProvider } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import Navigator from '../componenents/Navigator'
import Header from '../componenents/Header'
import theme from '../Styles/CoreTheme'
import { Copyright } from '../componenents/Copyright'
import Grid from '@mui/material/Grid'
import { Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import ClientsTab from '../componenents/ClientsTab'
import SessionsTab from '../componenents/SessionsTab'
import SaveCaseDialog from '../componenents/SaveCaseDialog'
import AddClientDialog from '../componenents/ClientsTab/AddClientDialog'
import EditSessionDialog from '../componenents/SessionsTab/EditSessionDialog'
import CreateSessionDialog from '../componenents/SessionsTab/CreateSessionDialog'
import { DialogsContext } from '../context/dialogsContext'
import ManageCaseDialog from '../componenents/ManageCaseDialog'
import ClientMenuDialog from '../componenents/ClientMenuDialog'

const drawerWidth = 256

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  tabIndex: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, tabIndex, index, ...other } = props
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));


  return (
    <div
      role='tabpanel'
      hidden={tabIndex !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {tabIndex === index && (
        <Box sx={{ py: 3, px: isSmDown ? 2 : 4, bgcolor: '#eaeff1' }}>{children}</Box>
      )}
    </div>
  )
}

export default function Core() {
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))
  const [tabIndex, setTabIndex] = React.useState(0)
  const { editSessionDialog } = React.useContext(DialogsContext);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  return (
    <ThemeProvider theme={theme}>
      <Box display="flex" >
        <CssBaseline />
        <Box component='nav' sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}>
          {isSmUp ? null : (
            <Navigator
              PaperProps={{ style: { width: drawerWidth } }}
              variant='temporary'
              open={mobileOpen}
              onClose={handleDrawerToggle}
            />
          )}
          <Navigator
            PaperProps={{ style: { width: drawerWidth } }}
            sx={{ display: { sm: 'block', xs: 'none' } }}
          />
        </Box>
        <Box sx={{ position: 'fixed', bottom: 0, top: 0, right: 0, left: (isSmUp ? drawerWidth : 0), flexDirection: 'column', bgcolor: '#eaeff1' }}>
          <Header
            onDrawerToggle={handleDrawerToggle}
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
          />
          <CustomTabPanel tabIndex={tabIndex} index={0}>
            <ClientsTab />
          </CustomTabPanel>
          <Box component='footer' >
            <Copyright />
          </Box>
        </Box>
      </Box>
      <AddClientDialog />
      <SaveCaseDialog />
      <ManageCaseDialog />
      <ClientMenuDialog />
      {editSessionDialog.show && <EditSessionDialog />}
      <CreateSessionDialog />
    </ThemeProvider >
  )
}
