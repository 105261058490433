import { ChevronRightRounded } from '@mui/icons-material';
import { Button, Grid, Typography, Divider, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { DateTime } from 'luxon';
import { CaseType, SessionType } from '../../types';
import { useContext } from 'react';
import { ProtocolsContext } from '../../context/protocolsContext';
import theme from '../../Styles/CoreTheme';
import _ from 'lodash';
import { DialogsContext } from '../../context/dialogsContext';
import { UserContext } from '../../context/userContext';

interface Props {
    clientCase: CaseType,
    caseSessions: SessionType[],
}

const SessionsList = ({ clientCase, caseSessions }: Props) => {
    const { setEditSessionDialog, showSessionDialog, showCaseDialog } = useContext(DialogsContext);
    const { setViewSessionConfig } = useContext(DialogsContext);
    const { user } = useContext(UserContext);
    //      setCaseDialogState((prev) => ({ ...prev, show: show === undefined ? true : show }));

    const { protocols } = useContext(ProtocolsContext);
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));


    const getProtocols = (session: SessionType) => {
        if (session) {
            const sessionProtocols = protocols.filter((protocol) => session.protocols.includes(protocol.id));
            const protocolNames = sessionProtocols.map((protocol) => protocol.name);
            if (_.isEmpty(protocolNames)) {
                return '--';
            }
            return protocolNames;
        }
        return [];
    }

    const openSession = (session: SessionType) => {
        if (user.is_case_reviewer) {
            setViewSessionConfig({ show: true, session: session });
        } else {
            setEditSessionDialog({ show: true, currentSession: session, sessionCase: clientCase });
        }
    }

    return (
        <Box flexDirection={'row'} display={'flex'}>
            <Box sx={{ display: 'flex', flex: 1, backgroundColor: '#a7caed' }} />
            <Box sx={{ display: 'flex', flex: isSmDown ? 40 : 100 }} >
                <Box key={clientCase.id} width={'100%'}>
                    <Box sx={{ ml: 1, mb: 0 }}>
                        {(caseSessions && caseSessions.length > 0) ?
                            (
                                caseSessions.map((session: SessionType, i, row) => {
                                    const sessionDateTime = DateTime.fromISO(session.date).toLocaleString(DateTime.DATE_SHORT);
                                    return (
                                        <Box key={session.id}>
                                            <Button sx={{ flexDirection: 'row', display: 'flex', width: '100%' }} onClick={() => openSession(session)}>
                                                <Grid container spacing={2} >
                                                    <Grid container item xs={11} md={11}>
                                                        <Grid item xs={12} md={6} m={'auto'}>
                                                            <Typography color={'CaptionText'} sx={{ textAlign: 'left' }}>{getProtocols(session)}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={6} m={'auto'}>
                                                            <Typography color={'CaptionText'} sx={{ textAlign: isSmDown ? 'left' : 'right' }}>{sessionDateTime}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={1} m={'auto'}>
                                                        <ChevronRightRounded color='action' sx={{ pt: 0.2 }} />
                                                    </Grid>
                                                </Grid>
                                            </Button>
                                            {i + 1 !== row.length && <Divider />}
                                        </Box>
                                    )
                                })
                            )
                            :
                            (
                                <Box flexDirection={'row'} display={'flex'}>
                                    <Typography m={1} mb={0} color='CaptionText'>No Sessions</Typography>
                                </Box>
                            )}
                    </Box>
                </Box>
            </Box>
        </Box >
    )
}

export default SessionsList;