// import React, { useContext, useState } from 'react';
// import { useForm, Controller } from 'react-hook-form';
// import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
// import Dialog from '@mui/material/Dialog';
// import DialogContent from '@mui/material/DialogContent';
// import DialogTitle from '@mui/material/DialogTitle';
// import { LoginDialogsContext } from '../../context/loginDialogsContext';

// interface FormData {
//     email: string;
// }

// const ForgotPasswordDialog: React.FC = () => {
//     const { showResetPassword, setShowResetPassword } = useContext(LoginDialogsContext);
//     const { control, handleSubmit } = useForm<FormData>();

//     const onSubmit = (data: FormData) => {
//         // Handle the form submission, e.g., send a reset password link to the user's email.
//     };

//     const handleOpen = () => {
//         setShowResetPassword(true);
//     };

//     const handleClose = () => {
//         setShowResetPassword(false);
//     };

//     return (
//         <Dialog open={showResetPassword} onClose={handleClose}>
//             <DialogTitle>Forgot Password</DialogTitle>
//             <DialogContent>
//                 <form onSubmit={handleSubmit(onSubmit)}>
//                     <Controller
//                         name="email"
//                         control={control}
//                         defaultValue=""
//                         render={({ field }) => (
//                             <TextField
//                                 {...field}
//                                 label="Email"
//                                 variant="outlined"
//                                 fullWidth
//                             />
//                         )}
//                     />
//                     <Button type="submit" variant="contained" color="primary">
//                         Send Reset Link
//                     </Button>
//                 </form>
//             </DialogContent>
//         </Dialog>
//     );
// };

// export default ForgotPasswordDialog;

import React, { useContext, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { LoginDialogsContext } from '../../context/loginDialogsContext';
import { Box, getValue } from '@mui/system';
import { resetPassword } from '../../firebase/firebaseAuth';

interface FormData {
    email: string;
}

const ForgotPasswordDialog: React.FC = () => {
    const { showResetPassword: open, setShowResetPassword: setOpen } = useContext(LoginDialogsContext);
    const { control, handleSubmit, getValues } = useForm<FormData>();

    const onSubmit = () => {
        const { email } = getValues();
        resetPassword(email);
        handleClose();
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Forgot Password</DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                        name="email"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Email"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                            />
                        )}
                    />
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button type="submit" variant="contained" color="secondary">
                            Send Reset Link
                        </Button>
                    </DialogActions>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default ForgotPasswordDialog;
