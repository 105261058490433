import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Autocomplete,
  Box,
  Button,
  Chip,
  CssBaseline,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material'
import { ArrowLeftIcon, DatePicker, DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'

import React, { ReactNode, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { Controller, FieldValues, useForm } from 'react-hook-form'
import { ClientsContext } from '../../context/clientsContext'
import { CasesContext } from '../../context/casesContext'
import { BorderColor, Close, Done, KeyboardArrowDown, KeyboardArrowRight, More } from '@mui/icons-material'
import { sessionType, syptomIntensityOptions } from './constants'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AreaStateSetter from './AreaStateSetter'
import { AreasContext } from '../../context/areasContext'
import { ProtocolsContext } from '../../context/protocolsContext'
import { AreaType, CaseType, CreatedProtocolType, FakeUser, ProtocolType, RecommendationType, SessionType, ageOptions, yesNoOptions } from '../../types'
import { DateTime } from 'luxon'
import { CreatedProtocolsContext } from '../../context/createdProtocolsContext'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { AreasStateContext } from '../../context/areasSateContext'
import { DialogsContext } from '../../context/dialogsContext'
import { SessionsContext } from '../../context/sessionsContext'
import _, { min } from 'lodash'
import { string } from 'yup'
import { useDebounce } from '../Debounce'
import { RecommendationsContext } from '../../context/recommendationsContext'
import Area from '../Area'
import ConfirmDialog from '../ConfirmDialog'
import { getDisplayCaseState } from '../ClientsTab/ClientsTable'
import { displayEditEnabledCaseStates } from '../../constants'

export const getProtocolLabel = (protocol: ProtocolType) => {
  console.log('tracker p: ', JSON.stringify(protocol))
  const ref = protocol.reference;
  let bookAbbreviation = '';
  if (ref && ref.book) {
    if (ref.book.id === 1) {
      bookAbbreviation = 'MTPH';
    } else if (ref.book.id === 2) {
      bookAbbreviation = 'APH';
    } else if (ref.book.id === 3) {
      bookAbbreviation = 'PP';
    } else if (ref.book.id === 4) {
      bookAbbreviation = 'PCH'
    }
  } else {
    return protocol.name;
  }
  const rawPage = protocol.reference.page_number;
  const page = rawPage.endsWith('.0') ? rawPage.slice(0, -2) : rawPage;
  return `${protocol.name} (${bookAbbreviation} p.${page})`
}

export default function EditSessionDialog() {
  const { clients, getClient } = React.useContext(ClientsContext);
  const { sessions, deleteSession, editSession, refreshSessions } = React.useContext(SessionsContext);
  const { cases: clientCases, getCase, refreshCases } = React.useContext(CasesContext);
  const [caseSessions, setCaseSessions] = React.useState([] as SessionType[]);
  const { areas } = React.useContext(AreasContext);
  const { areasState } = React.useContext(AreasStateContext);
  const { protocols, getProtocol, getProtocols } = React.useContext(ProtocolsContext);
  const { recommendations, getRecommendation, getRecommendations } = React.useContext(RecommendationsContext);
  const { createdProtocols } = React.useContext(CreatedProtocolsContext);
  const { editSessionDialog, setEditSessionDialog } = React.useContext(DialogsContext);
  const [displayAreas, setDisplayAreas] = React.useState([] as AreaType[]);
  const { saveCasePurpose } = React.useContext(CasesContext);
  const debounceEditSession = useDebounce(editSession, 200);
  const debounceManageCase = useDebounce(saveCasePurpose, 200);
  const [customProtocolSelected, setCustomProtocolSelected] = useState(false);
  const [sessionId, setSessionId] = useState(0);
  const [sessionClient, setSessionClient] = useState(FakeUser);
  const [majorChakrasExpanded, setMajorChakrasExpanded] = useState(false);
  const [minorChakrasExpanded, setMinorChakrasExpanded] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(true);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValidating, isDirty, dirtyFields },
    reset,
    watch,
    getValues,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      id: 0,
      client: FakeUser,
      clientCase: {} as CaseType,
      date: '2023-08-30T07:02:23Z',
      session_protocols: [] as ProtocolType[],
      session_recommendations: [] as RecommendationType[],
      previous_session_notes: '',
      pre_session_notes: '',
      protocol_notes: '',
      post_session_client_notes: '',
      protocols_used: [] as ProtocolType[],
      created_protocols_used: [] as CreatedProtocolType[],
      areas_states: [] as AreaType[],
      client_reviewed_on: '2023-08-30T07:02:23Z',
      other_observations_notes: '',
      remote: 'NO_ANSWER',
      purpose: '',
    },
  });

  useEffect(() => {
    const session = editSessionDialog.currentSession;
    const sessionCase = clientCases.find((cc) => cc.id === session.case);
    if (sessionCase) {
      const status = getDisplayCaseState(sessionCase);
      setIsReadOnly(!displayEditEnabledCaseStates.includes(status));
    }
  }, [])

  useEffect(() => {
    if (areasState && areas) {
      const areasFromStates = areasState.map((states) => states.area);
      const mergedStates = _.unionBy(areasFromStates, areas, 'id');
      const sortedStates = _.sortBy(mergedStates, ['id']);
      setDisplayAreas(sortedStates);
    }
  }, [areasState]);

  const getMillis = (date: string) => {
    return DateTime.fromISO(date).toMillis();
  }

  useEffect(() => {
    if (sessions && clientCases) {
      const filteredSessions = sessions.filter((s: SessionType) => s.case === editSessionDialog.currentSession.case);
      filteredSessions.sort((a, b) => getMillis(b.date) - getMillis(a.date))
      setCaseSessions(filteredSessions);
    }
  }, [sessions, clientCases]);

  useEffect(() => {
    const session = editSessionDialog.currentSession;
    const firstSession = getFirstSession();
    let tempCase;
    if (firstSession) {
      tempCase = getCase(firstSession.id);
    }

    setSessionId(session.id);
    if (editSessionDialog && !_.isEmpty(session)) {
      const defaults = {
        id: session.id,
        client: getClient(session.client) || FakeUser,
        clientCase: editSessionDialog.sessionCase,
        date: session.date,
        session_protocols: getProtocols(session.protocols),
        session_recommendations: getRecommendations(session.recommendations),
        previous_session_notes: session.previous_session_notes,
        pre_session_notes: session.pre_session_notes,
        protocol_notes: session.protocol_notes,
        post_session_client_notes: session.post_session_client_notes,
        remote: session.remote || 'NO_ANSWER',
        purpose: editSessionDialog.sessionCase.purpose || ''
      }
      reset(defaults)
    }

  }, [editSessionDialog, clients, areas, protocols, recommendations, createdProtocols]);

  const handleClose = () => {
    saveFormValues();
    refreshSessions();
    refreshCases();
    setEditSessionDialog((prev) => ({ ...prev, show: false }))
  }

  const getIdsArray = (arr: any) => {
    if (arr) {
      return arr.map((item: any) => item.id);
    }
  }

  const onChangeHandler = () => {
    saveFormValues();
    setCustomProtocolSelected(getValues().session_protocols.find((p) => p.id == 2) !== undefined);
  }

  const saveFormValues = () => {
    const session = editSessionDialog.currentSession;
    const sessionCase = clientCases.find((cc) => cc.id === session.case);
    if (session && sessionCase) {
      const values = getValues();
      const purpose = values.purpose;
      const protocolsIds = getIdsArray(values.session_protocols);
      const recommendationsIds = values.session_recommendations.map(item => item.id)
      const caseId = sessionCase.id;
      const tempData: any = values;
      delete tempData.client;
      delete tempData.clientCase;
      delete tempData.areas_states;
      delete tempData.session_protocols;
      delete tempData.session_recommendations;
      delete tempData.purpose;

      const tempSession = {
        ...tempData,
        client: values.client ? values.client.id : session.client,
        ...(protocolsIds ? { protocols: protocolsIds } : {}),
        ...(recommendationsIds ? { recommendations: recommendationsIds } : {}),
        remote: values.remote,
        case: caseId,
      };
      debounceEditSession(session.id, tempSession);
      if (purpose) {
        debounceManageCase(caseId, purpose);
      }
    }

  }

  const getDisplayName = () => {
    const client = getClient(editSessionDialog.currentSession.client);
    return client?.full_name || client?.email;
  }

  const getDisplayAgeRange = () => {
    const client = getClient(editSessionDialog.currentSession.client);
    const ar = client?.age_range;
    if (ar) {
      const key = ar;
      return ageOptions[key];
    }
    return 'no answer';
  }


  const getDisplayPossiblyPregnant = () => {
    const client = getClient(editSessionDialog.currentSession.client);
    const pp = client?.possibly_pregnant;
    if (pp) {
      const key = pp;
      return yesNoOptions[key];
    } else {
      return 'no answer'
    }
  }

  const getFirstSession = () => {
    if (caseSessions.length === 0) {
      return null; // Return null for an empty array
    }

    return caseSessions.reduce((earliest, current) => {
      return current.date < earliest.date ? current : earliest;
    }, caseSessions[0]);
  };

  const isFirstSession = useMemo(() => {
    if (caseSessions.length < 1) {
      return true;
    }
    const firstSession = getFirstSession();
    if (firstSession) {
      return editSessionDialog.currentSession.id === firstSession.id || editSessionDialog.currentSession.date === firstSession.date;
    }
    return false;
  }, [caseSessions, clientCases, sessions])

  const getDisplayHBPD = () => {
    const client = getClient(editSessionDialog.currentSession.client);
    const HBPD = client?.high_blood_pressure_diagnosed;
    if (HBPD) {
      const key = HBPD;
      return yesNoOptions[key];
    }
    return 'no answer';
  }

  const getRemoteRadioValue = () => {
    const session = editSessionDialog.currentSession;
    if (session && session.remote) {
      return session.remote;
    }
    return null;
  }

  const formContainerStyle = { backgroundColor: 'whitesmoke', borderRadius: 2, p: 2 };

  const renderTags = (value: ProtocolType[], getTagProps: any) => {
    return value.map((protocol, index) => {
      const label = getProtocolLabel(protocol)

      return (<Chip
        key={label}
        label={<Typography fontSize={'small'} sx={{ whiteSpace: 'normal', my: 0.5 }}>{label}</Typography>}
        {...getTagProps(index)}
        style={{
          height: '100%'
        }}
      />
      )
    });
  };

  return (
    <div>
      <Dialog fullScreen open={editSessionDialog.show}>
        <CssBaseline />
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton sx={{ marginTop: 0.3 }} edge='start' color='inherit' onClick={handleClose} aria-label='close'>
              <Typography variant='overline' sx={{ fontWeight: 'bold' }}>Done</Typography>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Divider />
        <DialogContent sx={{ maxWidth: 800, alignSelf: 'center' }}>
          <DialogContentText>
            Avoid using clients name in the notes below to maintain privacy during case review
          </DialogContentText>
          <Box m={2} mt={4} />
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <form noValidate onChange={onChangeHandler}>
              <Typography sx={{ fontSize: 18, color: 'grey' }}>Purpose</Typography>
              <Box m={1} />
              <Grid container sx={formContainerStyle}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid item>
                    <Controller
                      render={({ field }) => (
                        <TextField
                          disabled={!isFirstSession}
                          {...field}
                          label={'purpose of visit (symptoms, issues, conditions):'}
                          fullWidth
                          multiline
                          minRows={4}
                          InputProps={{ readOnly: isReadOnly }}
                        />
                      )}
                      name='purpose'
                      control={control}
                    />
                    <Typography sx={{ fontSize: 12, color: 'grey', p: 1, pb: 0 }}>edit from first session</Typography>
                  </Grid>
                </Grid>
              </Grid>
              {!isFirstSession &&
                <>
                  <Box m={3} />
                  <Typography sx={{ fontSize: 18, color: 'grey' }}>Clients Comments After Previous Session</Typography>
                  <Box m={1} />
                  <Grid container sx={formContainerStyle}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Grid item>
                        <Controller
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label={'previous session notes'}
                              fullWidth
                              multiline
                              minRows={4}
                              InputProps={{ readOnly: isReadOnly }}
                            />
                          )}
                          name='previous_session_notes'
                          control={control}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              }
              <Box m={3} />
              <Typography sx={{ fontSize: 18, color: 'grey' }}>Session Information</Typography>
              <Box m={1} />
              <Grid container sx={formContainerStyle}>
                <Grid item xs={10} sm={10} md={3} lg={3}>
                  {/* DATE PICKER */}
                  <Controller
                    control={control}
                    name='date'
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        value={DateTime.fromISO(value).toLocal()}
                        label='Session Date'
                        onChange={(date) => onChange(date)}
                        readOnly={isReadOnly}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            variant: 'standard',
                            error: !!errors.date,
                            helperText: errors?.date && 'Date required',
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} />
                <Grid item container direction={'column'} xs={12} sm={12} md={6}>
                  <Typography variant='overline'>Client</Typography>
                  <Typography variant='subtitle1'>{getDisplayName()}</Typography>
                </Grid>
                <Grid item container direction={'column'} xs={12} sm={12} md={6}>
                  <Typography variant='overline'>Case Name</Typography>
                  <Typography variant='subtitle1'>{getCase(editSessionDialog.currentSession.case)?.label}</Typography>
                </Grid>
                <Grid item container direction={'column'} xs={12} sm={12} md={6}>
                  <Typography variant='overline'>Age Range</Typography>
                  <Typography variant='subtitle1'>{getDisplayAgeRange()}</Typography>
                </Grid>
                <Grid item container direction={'column'} xs={12} sm={12} md={6}>
                  <Typography variant='overline'>Possibly Pregnant</Typography>
                  <Typography variant='subtitle1'>{getDisplayPossiblyPregnant()}</Typography>
                </Grid>
                <Grid item container direction={'column'} xs={12} sm={12} md={6}>
                  <Typography variant='overline'>High Blood Pressure Diagnosed</Typography>
                  <Typography variant='subtitle1'>{getDisplayHBPD()}</Typography>
                </Grid>
              </Grid>
              <Box m={2} mt={4} />
              <Typography sx={{ fontSize: 18, color: 'grey' }}>Conditions Before Session</Typography>
              <Box m={1} />
              <Grid container direction={'column'} sx={formContainerStyle}>
                <Grid item>
                  <Accordion elevation={0} sx={{ borderRadius: 1.3 }} expanded={majorChakrasExpanded} onChange={() => setMajorChakrasExpanded(!majorChakrasExpanded)}>
                    <AccordionSummary
                      id='panel1a-header'
                      sx={{ p: 0, m: 0, backgroundColor: 'whitesmoke' }}
                    >
                      <Typography sx={{ fontSize: 14, fontWeight: 'bold', color: 'grey' }}>
                        Major Chakras
                      </Typography>
                      <Button sx={{ p: 0, m: 0, mt: -0.3 }}>{majorChakrasExpanded ? 'close' : 'open'}</Button>
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0, m: 0, backgroundColor: 'whitesmoke' }}>
                      <Grid container columns={{ xs: 4, sm: 8, md: 8 }} spacing={2}>
                        {/* PRE SESSION SCAN BUTTONS */}
                        {!isReadOnly && displayAreas.filter((a) => a.major).map((area) => (
                          <Grid item key={area.id} xs={4} sm={4} md={4}>
                            <AreaStateSetter
                              key={area.id}
                              area={area}
                              sessionType={sessionType.PRE_SESSION}
                            />
                          </Grid>
                        ))}
                        <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                          <Button sx={{ p: 2, m: 1, mb: 0 }} onClick={() => setMajorChakrasExpanded(!majorChakrasExpanded)}>{majorChakrasExpanded ? 'close major chakras' : 'open'}</Button>
                        </Box>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item>
                  <Accordion elevation={0} sx={{ borderRadius: 1.3 }} expanded={minorChakrasExpanded} onChange={() => setMinorChakrasExpanded(!minorChakrasExpanded)}>
                    <AccordionSummary
                      id='panel1a-header'
                      sx={{ p: 0, m: 0, backgroundColor: 'whitesmoke' }}
                    >
                      <Typography sx={{ fontSize: 14, fontWeight: 'bold', color: 'grey' }}>
                        Minor Chakras
                      </Typography>
                      <Button sx={{ p: 0, m: 0, mt: -0.3 }}>{minorChakrasExpanded ? 'close' : 'open'}</Button>
                    </AccordionSummary>
                    <AccordionDetails sx={{ m: 0, p: 0, backgroundColor: 'whitesmoke' }}>
                      <Grid container columns={{ xs: 4, sm: 8, md: 8 }} spacing={2}>
                        {/* PRE SESSION SCAN BUTTONS */}
                        {!isReadOnly && displayAreas.filter((a) => !a.major).map((area) => (
                          <Grid item key={area.id} xs={4} sm={4} md={4}>
                            <AreaStateSetter
                              key={area.id}
                              area={area}
                              sessionType={sessionType.PRE_SESSION}
                            />
                          </Grid>
                        ))}
                        <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                          <Button sx={{ p: 2, m: 1, mb: 0 }} onClick={() => setMinorChakrasExpanded(!minorChakrasExpanded)}>{minorChakrasExpanded ? 'close minor chakras' : 'open'}</Button>
                        </Box>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Box m={1} />
                <Grid item>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={'Other Observations'}
                        fullWidth
                        multiline
                        minRows={4}
                        maxRows={8}
                        InputProps={{ readOnly: isReadOnly }}
                      />
                    )}
                    name='pre_session_notes'
                    control={control}
                  />
                </Grid>
              </Grid>
              <Box m={2} mt={4} />
              <Typography sx={{ fontSize: 18, color: 'grey' }}>Session</Typography>
              <Box m={1} />
              <Grid container direction={'column'} sx={formContainerStyle}>
                {/* <Area circleThickness={2} // Adjust the thickness as needed
                  numCircles={6} // Adjust the number of circles as needed
                  circleColor="grey" // Adjust the circle color as needed
                /> */}
                <Controller
                  name="remote"
                  control={control}
                  defaultValue={getRemoteRadioValue() || ''}
                  render={({ field }) => (
                    <RadioGroup
                      {...field}
                      aria-label="choice"
                      name="choice"
                      row
                    >
                      <FormControlLabel
                        value="IN_PERSON"
                        control={<Radio sx={{ mb: 0.4 }} disabled={isReadOnly} />}
                        label="In Person"
                      />
                      <FormControlLabel
                        value="REMOTE"
                        control={<Radio sx={{ mb: 0.4 }} disabled={isReadOnly} />}
                        label="Remote"
                      />
                    </RadioGroup>
                  )}
                />
                <Grid item>
                  <Controller
                    control={control}
                    name='session_protocols'
                    render={({ field: { ref, onChange, value, ...field } }) => (
                      <Autocomplete
                        readOnly={isReadOnly}
                        {...field}
                        multiple
                        onChange={(event, values, reason) => {
                          onChange(values)
                          onChangeHandler()
                        }}
                        value={value || []}
                        options={protocols ? protocols : []}
                        getOptionLabel={(protocol) => getProtocolLabel(protocol)}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderTags={renderTags}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            inputRef={ref}
                            label='Protocols From Books'
                            margin='normal'
                            variant='outlined'
                            error={!!errors.session_protocols}
                            helperText={errors.session_protocols && 'Protocol required'}
                          />
                        )}
                      />
                    )}
                  />
                  <Grid item>
                    <Box m={2} />
                    <Controller
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label={'Protocol Modifications'}
                          fullWidth
                          multiline
                          minRows={4}
                          maxRows={10}
                          InputProps={{ readOnly: isReadOnly }}
                        />
                      )}
                      name='protocol_notes'
                      control={control}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Box m={2} mt={4} />
              <Typography sx={{ fontSize: 18, color: 'grey' }}>Post Session Notes</Typography>
              <Box m={1} />
              <Grid container direction={'column'} sx={formContainerStyle}>
                <Grid item>
                  <Controller
                    control={control}
                    name='session_recommendations'
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        readOnly={isReadOnly}
                        multiple
                        onChange={(event, values, reason) => {
                          onChange(values)
                          onChangeHandler()
                        }}
                        value={value || []}
                        options={recommendations ? recommendations : []}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label='Recommendations'
                            margin='normal'
                            variant='outlined'
                            error={!!errors.session_recommendations}
                            helperText={errors.session_recommendations && 'Recommendation required'}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Box m={1} />
                <Grid item>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={'Clients Comments After Session'}
                        fullWidth
                        multiline
                        minRows={4}
                        maxRows={8}
                        InputProps={{ readOnly: isReadOnly }}
                      />
                    )}
                    name='post_session_client_notes'
                    control={control}
                  />
                </Grid>
              </Grid>
              {!isReadOnly && <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                <Button sx={{ p: 2, m: 1, mb: 0, mt: 3, color: 'red' }} onClick={() => setShowConfirmDelete(true)}>DELETE</Button>
              </Box>}
            </form>
          </LocalizationProvider>
          <Box m={1} />
        </DialogContent>
      </Dialog>
      <ConfirmDialog
        open={showConfirmDelete}
        title="Are you sure you want to delete session?"
        message="Session information will be deleted permanently"
        onConfirm={() => {
          setShowConfirmDelete(false);
          deleteSession(editSessionDialog.currentSession.id);
          handleClose();
        }}
        onCancel={() => {
          setShowConfirmDelete(false);
        }}
      />
    </div >
  )
}
