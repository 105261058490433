import React, { Dispatch, SetStateAction, createContext, useEffect, useMemo, useState } from 'react'
import { ProtocolType } from '../types'
import { useAxiosInstance } from '../api/AxiosInstanceContext'

interface ProtocolsContext {
  protocols: ProtocolType[]
  protocolsError: string | null
  getProtocol: (protocolId: number) => ProtocolType | undefined;
  getProtocols: (protocolsIds: number[]) => (ProtocolType | undefined)[];
}

export const ProtocolsContext = createContext<ProtocolsContext>(undefined as any)

interface Props {
  children: React.ReactNode
}

export const ProtocolsProvider: React.FC<Props> = ({ children }) => {
  const axiosInstance = useAxiosInstance();
  const [protocols, setProtocols] = useState<ProtocolType[]>([] as ProtocolType[]);
  const [protocolsError, setProtocolsError] = useState<string | null>(null);

  useEffect(() => {
    axiosInstance.get('/protocols')
      .then(({ data }) => setProtocols(data))
      .catch((e) => setProtocolsError(e));
  }, [])

  const getProtocol = (protocolId: number) => {
    if (protocolId && protocols && protocols !== undefined) {
      return protocols.find((c: ProtocolType) => c.id === protocolId);
    }
  };

  const getProtocols = (protocolsIds: number[]) => {
    return protocolsIds.map((index) => getProtocol(index));
  };

  const values = {
    protocols: protocols ? protocols : [],
    protocolsError,
    getProtocol,
    getProtocols,
  };

  return <ProtocolsContext.Provider value={values}>{children}</ProtocolsContext.Provider>
};
