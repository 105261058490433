import { Box, Button, Divider, Grid, Paper, ThemeProvider, Typography } from '@mui/material'
import { AreaType } from '../../types'
import { activationOptions, energyLevelOptions } from './constants'
import { useContext } from 'react'
import { theme } from './AreaStateSetterStyle'
import { AreasStateContext } from '../../context/areasSateContext'
import { DialogsContext } from '../../context/dialogsContext'

const AreaStateSetter = ({ area, sessionType }: { area: AreaType; sessionType: string }) => {
  const { saveAreaState: save, areasState } = useContext(AreasStateContext)
  const { editSessionDialog } = useContext(DialogsContext)

  const getColor = (id: number) => {
    let color = 'primary'
    switch (id) {
      case 0:
        color = 'primary'
        break
      case 6:
        color = 'warning'
        break
      case 12:
        color = 'secondary'
        break
      case 18:
        color = 'error'
        break
    }
    return color as any
  }

  const getActivationButtonState = (id: number): any => {
    const areaState = areasState.find((areaState) => {
      if (areaState.area && areaState.area.id === area.id && areaState.type === sessionType) {
        return true
      }
      return false
    })
    if (areaState && areaState.activation === id) {
      return 'contained'
    }
    return 'outlined'
  }

  const getEnergyLevelButtonState = (id: number): any => {
    const areaState = areasState.find((areaState) => {
      if (areaState.area && areaState.area.id === area.id && areaState.type === sessionType) {
        return true
      }
      return false
    })
    if (areaState && areaState.energy_level === id) {
      return 'contained'
    }
    return 'outlined'
  }

  const saveAreaState = ({
    activation,
    energy_level,
  }: {
    activation?: number
    energy_level?: number
  }) => {
    save({
      session_id: editSessionDialog.currentSession.id,
      area_id: area.id,
      type: sessionType,
      ...(activation || activation === 0 ? { activation: activation } : {}),
      ...(energy_level || energy_level === 0 ? { energy_level: energy_level } : {}),
    })
  }

  return (
    <ThemeProvider theme={theme} >
      <Box sx={{ backgroundColor: 'whitesmoke' }}>
        <Grid item sx={{ backgroundColor: 'whitesmoke' }}>
          <Paper
            elevation={0}
            sx={{
              border: (theme) => `4px solid ${theme.palette.divider}`,
              padding: 1,
              backgroundColor: 'whitesmoke'
            }}
          >
            <Typography sx={{ fontSize: 14, fontWeight: 'bold', marginBottom: 1, color: 'grey' }}>
              {area.name.toUpperCase()}
            </Typography>
            <Divider />
            <Box m={2} />
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box
                sx={{ display: 'flex', width: '100%', flexDirection: 'column', overflow: 'hidden' }}
              >
                <Box alignContent={'center'}>
                  <Typography sx={{ fontSize: 12, color: 'grey' }}>ACTIVATION</Typography>
                </Box>
                {activationOptions.map((state) => (
                  <Button
                    key={state.id}
                    disableElevation
                    onClick={() => saveAreaState({ activation: state.id })}
                    variant={getActivationButtonState(state.id)}
                    color={getColor(state.id)}
                  >
                    {state.label.toLowerCase()}
                  </Button>
                ))}
              </Box>
              <Box m={2} />
              <Box
                sx={{ display: 'flex', width: '100%', flexDirection: 'column', overflow: 'hidden' }}
              >
                <Box alignContent={'center'}>
                  <Typography sx={{ fontSize: 12, color: 'grey' }}>ENERGY LEVEL</Typography>
                </Box>
                {energyLevelOptions.map((state) => (
                  <Button
                    sx={{ width: '100%' }}
                    key={state.id}
                    disableElevation
                    onClick={() => saveAreaState({ energy_level: state.id })}
                    variant={getEnergyLevelButtonState(state.id)}
                    color={getColor(state.id)}
                  >
                    {state.label.toLowerCase()}
                  </Button>
                ))}
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Box>
    </ThemeProvider >
  )
}

export default AreaStateSetter
