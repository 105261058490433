import MenuIcon from '@mui/icons-material/Menu'
import AppBar from '@mui/material/AppBar'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import * as React from 'react'
import { useContext, useState } from 'react'
import { UserContext } from '../context/userContext'
import { WaiverContext } from '../context/waiversContext'
import OnboardingModal from './OnboardingModal'
import CaseStatusesModal from './CaseStatusesModal'
import UserMenu from './UserMenu'
import _ from 'lodash'
import { AccountCircle, Checklist } from '@mui/icons-material'
import { Box } from '@mui/system'

const lightColor = 'rgba(255, 255, 255, 0.7)'

interface HeaderProps {
  onDrawerToggle: () => void
  tabIndex: number
  setTabIndex: React.Dispatch<React.SetStateAction<number>>
}

export default function Header(props: HeaderProps) {
  const { onDrawerToggle, tabIndex, setTabIndex } = props
  const [showUserMenu, setShowUserMenu] = useState(false)
  const [showOnboardingModal, setShowOnboardingModal] = useState<boolean>(true)
  const [showCaseStatuses, setShowCaseStatusesModal] = useState<boolean>(false)

  const { user } = useContext(UserContext)
  const { waiverResults } = useContext(WaiverContext)

  React.useEffect(() => {
    if (
      !_.isEmpty(user) &&
      waiverResults &&
      waiverResults.length === 0 &&
      user.is_healer &&
      user.onboarded
    ) {
      setShowOnboardingModal(false);
    }
  }, [user, waiverResults])

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue)
  }

  return (
    <React.Fragment>
      <AppBar color='primary' position='static' elevation={0}>
        <Toolbar sx={{ height: 55 }}>
          <Grid container spacing={1} alignItems='center'>
            <Grid sx={{ display: { sm: 'none', xs: 'block' } }} item>
              <IconButton
                color='inherit'
                aria-label='open drawer'
                onClick={onDrawerToggle}
                edge='start'
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs />
            <Grid item>
              <Link
                variant='body2'
                style={{ cursor: 'pointer' }}
                sx={{
                  textDecoration: 'none',
                  color: lightColor,
                  '&:hover': {
                    color: 'common.white',
                  },
                }}
                rel='noopener noreferrer'
                target='_blank'
                onClick={() => {
                  window.location.href = 'mailto:certification@pranastudies.com?subject=App%20Feedback&body=Thank%20you%20for%20your%20feedback.';
                }}
              >
                App Feedback
              </Link>
            </Grid>
            <Grid m={1} />
            <Grid item>
              <Link
                variant='body2'

                sx={{
                  textDecoration: 'none',
                  color: lightColor,
                  '&:hover': {
                    color: 'common.white',
                  },
                }}
                rel='noopener noreferrer'
                target='_blank'
                onClick={() => {
                  window.location.href = 'mailto:certification@pranastudies.com?subject=App%20Support';
                }}
              >
                Support
              </Link>
            </Grid>
            <Grid m={1} />
            {!user.is_case_reviewer && <Grid item onClick={() => setShowCaseStatusesModal(true)}>
              <Box sx={{ display: 'flex' }}>
                <Tooltip title='Alerts • No alerts'>
                  <Checklist sx={{ fontSize: 38 }} />
                </Tooltip>
              </Box>
            </Grid>}
            <Grid item onClick={() => setShowUserMenu(true)}>
              <Box sx={{ display: 'flex' }}>
                <Tooltip sx={{ p: 0.5 }} title={'account info'}>
                  <AccountCircle sx={{ fontSize: 38, }} />
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>

        {/* <Tabs value={tabIndex} onChange={handleTabChange} textColor='inherit'>
          <Tab label='Clients' />
          <Tab label='Cases' />
          <Tab label='Sessions' />
        </Tabs> */}
      </AppBar>
      {!user.is_case_reviewer && <CaseStatusesModal open={showCaseStatuses} setOpen={setShowCaseStatusesModal} />}
      <OnboardingModal open={showOnboardingModal} setOpen={setShowOnboardingModal} />
      <UserMenu open={showUserMenu} setOpen={setShowUserMenu} />
    </React.Fragment>
  )
}
