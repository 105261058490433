import React, { useContext, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { UserContext } from '../../context/userContext';
import DropdownSelector from '../FormComponents/DropDownSelector';
import { User, ageOptions, yesNoOptions } from '../../types';
import { useFormContext } from './ProfileFormContext';


interface FormData extends Partial<User> { };


const ProfileForm: React.FC = () => {
  const { user } = useContext(UserContext); // Access user data from the context
  const { formMethods } = useFormContext();
  const { control, handleSubmit, setValue, formState: { errors } } = formMethods;

  const onSubmit = (data: FormData) => {
    console.log(data);
  };

  useEffect(() => {
    // Set the default values for the form fields based on user data
    setValue('first_name', user.first_name);
    setValue('last_name', user.last_name);
    setValue('phone_number', user.phone_number);
    setValue('country', user.country);
    setValue('state', user.state);
    setValue('age_range', user.age_range);
    setValue('possibly_pregnant', user.possibly_pregnant);
    setValue('high_blood_pressure_diagnosed', user.high_blood_pressure_diagnosed);

  }, [control, user]);

  const formAgeOptions = Object.entries(ageOptions).map(([value, label]) => ({
    label,
    value,
  }));
  const formYesNoOptions = Object.entries(yesNoOptions).map(([value, label]) => ({
    label,
    value,
  }));

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            name="first_name"
            control={control}
            render={({ field }) => (
              <TextField
                required
                {...field}
                label="First Name"
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="last_name"
            control={control}
            render={({ field }) => (
              <TextField
                required
                {...field}
                label="Last Name"
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </Grid>
        {!user.is_client && (
          <>
            <Grid item xs={12}>
              <Controller
                name="country"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    {...field}
                    label="Country"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="state"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    {...field}
                    label="State"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="phone_number"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Phone Number"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <DropdownSelector
                required
                name="age_range"
                control={control}
                label="Age Range"
                options={formAgeOptions}
              />
            </Grid>
            <Grid item xs={12}>
              <DropdownSelector
                required
                name="possibly_pregnant"
                control={control}
                label="Possibly Pregnant"
                options={formYesNoOptions}
              />
            </Grid>
            <Grid item xs={12}>
              <DropdownSelector
                required
                name="high_blood_pressure_diagnosed"
                control={control}
                label="High Blood Pressure"
                options={formYesNoOptions}
              />
            </Grid>
          </>
        )}

      </Grid>
    </form>
  );
};

export default ProfileForm;
