import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import * as React from 'react'
import Box from '@mui/material/Box'
export function Copyright(props: any) {
  return (
    <Box>
      <Typography
        textAlign='center'
        variant='body2'
        color='text.secondary'
        align='center'
        {...props}
        gutterBottom
      >
        {'Copyright © '}
        <Link color='inherit' href='https://pranastudies.com/'>
          Prana Studies
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    </Box>
  )
}
