import React from 'react';
import { Controller } from 'react-hook-form';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

interface DropdownSelectorProps {
    name: string;
    required?: boolean;
    control: any;
    label: string;
    options: { label: string; value: string }[];
}

const DropdownSelector: React.FC<DropdownSelectorProps> = ({ name, required = false, control, label, options }) => {
    return (
        <FormControl fullWidth>
            <InputLabel id={`${name}-label`}>{label}</InputLabel>
            <Controller
                name={name}
                control={control}
                defaultValue=""
                render={({ field }) => (
                    <Select
                        required={required}
                        {...field}
                        labelId={`${name}-label`}
                        label={label}
                        variant="outlined"
                    >
                        {options.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                )}
            />
        </FormControl>
    );
};

export default DropdownSelector;