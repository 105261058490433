import { IdTokenResult, User } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import { signOutUser, singInWithLink, userStateListener } from '../firebase/firebaseAuth'
import { createContext, useState, useEffect, ReactNode, Dispatch, SetStateAction } from 'react'
import axios from 'axios'
import Loading from '../componenents/Loading'

interface Props {
  children?: ReactNode
}

interface AuthContext {
  currentUser: User | null
  setCurrentUser: Dispatch<SetStateAction<User | null>>
  signOut: () => void
  idToken: string | null
  getRefreshedToken: () => Promise<string | null>
  idTokenResult: IdTokenResult | null
}

export const AuthContext = createContext<AuthContext>(undefined as any)

export const AuthProvider = ({ children }: Props) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null)
  const [idToken, setIdToken] = useState<string | null>(null)
  const [idTokenResult, setIdTokenResult] = useState<IdTokenResult | null>(null)


  const navigate = useNavigate()

  useEffect(() => {
    singInWithLink()
  }, [])

  useEffect(() => {
    const unsubscribe = userStateListener((user) => {
      if (user) {
        setCurrentUser(user)
        user.getIdToken().then((token) => {
          setIdToken(token);
        });
        user.getIdTokenResult().then((tokenResult) => {
          setIdTokenResult(tokenResult);
        });
      }
    })
    return unsubscribe
  }, [currentUser])

  const signOut = () => {
    signOutUser()
    navigate('/')
  }

  const getRefreshedToken = async () => {
    if (currentUser) {
      return await currentUser?.getIdToken(true)
    }
    return null
  }

  const value = {
    currentUser,
    setCurrentUser,
    signOut,
    idToken,
    getRefreshedToken,
    idTokenResult
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
