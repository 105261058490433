export const activationOptions = [
  { id: 0, label: 'NOT SCANNED' },
  { id: 6, label: 'UNDERACTIVATED' },
  { id: 12, label: 'NORMAL' },
  { id: 18, label: 'OVERACTIVATED' },
]

export const energyLevelOptions = [
  { id: 0, label: 'Not Scanned' },
  { id: 6, label: 'Depleted' },
  { id: 12, label: 'Normal' },
  { id: 18, label: 'Congested' },
]

export const sessionType = {
  PRE_SESSION: 'PRE_SESSION',
  POST_SESSION: 'POST_SESSION',
}

export const stateType = {
  ACTIVATION: 'ACTIVATION',
  ENERGY_LEVEL: 'ENERGY_LEVEL',
}

export const syptomIntensityOptions = [
  { id: 'ITS_COMPLICATED', label: 'Its Complicated (add details in notes)' },
  { id: 'NONE', label: 'None' },
  { id: 'MILD', label: 'Mild' },
  { id: 'MODERATE', label: 'Moderate' },
  { id: 'SEVERE', label: 'Severe' },
]
