import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { WaiverResult, WaiverType } from '../../types';
import { Typography } from '@mui/material';

export default function WaiverDialog({ waiver, show, setShow }: { waiver: WaiverType, show: boolean, setShow: React.Dispatch<React.SetStateAction<boolean>> }) {
    const handleClose = () => {
        setShow(false);
    };

    const descriptionElementRef = React.useRef<HTMLElement>(null);

    React.useEffect(() => {
        if (show) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [show]);

    return (
        <div>
            <Dialog
                open={show}
                onClose={handleClose}
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">{waiver.title}</DialogTitle>
                <DialogContent dividers={true}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <Typography variant="body1" component="pre" sx={{ whiteSpace: 'pre-wrap' }}>
                            {waiver.copy}
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Done</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}