import Box from '@mui/material/Box'
import { Alert, AlertTitle, Snackbar } from '@mui/material'
import * as React from 'react'
import { Dispatch, SetStateAction } from 'react'

export const AlertBannerType = {
  error: 'error',
  success: 'success',
}
const AlertBanner = ({
  type,
  message,
  setMessage,
  onClose,
}: {
  type: string
  message: string | null
  setMessage: Dispatch<SetStateAction<string | null>>
  onClose?: () => void
}) => {
  const getTitle = () => {
    switch (type) {
      case AlertBannerType.error:
        return 'Error'
      case AlertBannerType.success:
        return 'Success'
    }
  }
  const getSeverity = () => {
    switch (type) {
      case AlertBannerType.error:
        return 'error'
      case AlertBannerType.success:
        return 'success'
    }
  }
  return (
    <Box>
      <Snackbar
        open={!!message}
        autoHideDuration={5000}
        onClose={() => {
          setMessage(null)
          onClose && onClose()
        }}
        sx={{ width: '100%' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert severity={getSeverity()} variant='filled' sx={{ width: '80%' }}>
          <AlertTitle>{getTitle()}</AlertTitle>
          <strong>{message}</strong>
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default AlertBanner
