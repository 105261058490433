import React, { useContext } from 'react';
import { Container, Typography, Button, makeStyles, Box, Link, useMediaQuery } from '@mui/material';
import { verifyEmail } from '../../firebase/firebaseAuth';
import { AutoGraph } from '@mui/icons-material';
import theme from './theme';
import { UserContext } from '../../context/userContext';


const VerifyEmailScreen: React.FC = () => {
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
    const { user } = useContext(UserContext);


    const handleResendClick = () => {
        // Handle the logic to resend the verification email
        verifyEmail();
    };

    return (
        <Container maxWidth="sm" sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <Box sx={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
                <AutoGraph sx={{ mr: 1, mt: isSmDown ? 0.5 : 0.2 }} />
                <Link variant={isSmDown ? 'h6' : 'h3'} underline='none' color='inherit' sx={{ fontSize: 28, mb: 4 }}>
                    {'Prana Records'}
                </Link>
            </Box>
            <Typography variant='h6' align="center" color='gray'>
                Check your email
            </Typography>
            <Box border={1} borderColor="gray" p={1} px={2} borderRadius={1} mt={2}>
                <Typography variant='subtitle1' align="center" color='gray'>
                    {user.email}
                </Typography>
            </Box>
            <Button
                color="primary"
                onClick={handleResendClick}
                sx={{ mt: 2 }}
            >
                Resend Verification Email
            </Button>
        </Container>
    );
};

export default VerifyEmailScreen;
