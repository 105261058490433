import * as React from 'react'
import { Box, Container, Paper } from '@mui/material'
import Typography from '../../routes/LandingPage/Typography'

const Welcome = ({ title, copy }: { title?: string, copy?: string }) => {
  return (
    <Container maxWidth="sm">
      <Paper elevation={0} sx={{
        padding: 3,
        marginTop: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
        <Typography variant="h4" align="center">
          {title}
        </Typography>
        <Typography variant="body1" align="center" mt={2}>
          {copy}
        </Typography>
      </Paper>
    </Container>
  )
}

export default Welcome
