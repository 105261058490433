import * as React from 'react'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { Box, Checkbox, FormControlLabel, Paper, Skeleton } from '@mui/material'
import { WaiverResult } from '../../types'
import { WaiverContext } from '../../context/waiversContext'

const Waiver = ({
  waiverResult,
}: {
  waiverResult: WaiverResult
}) => {
  const { waiversTempResults, setWaiversTempResults } = React.useContext(WaiverContext);
  const [waiver, setWaiver] = React.useState<any>()
  const [isChecked, setIsChecked] = React.useState(false);

  const handleOnChange = (event: any) => {
    const tempResult = { id: waiverResult.id, accepted: event.target.checked ? 'Y' : 'N', waiver: waiver };
    const oldResult = waiversTempResults.find((result) => result.id === waiverResult.id);
    if (oldResult) {
      const newResults = waiversTempResults?.map((result) => result.id === tempResult.id ? tempResult : result);
      setWaiversTempResults(newResults)
    } else {
      setWaiversTempResults([...waiversTempResults, ...[tempResult]]);
    }
  }

  React.useEffect(() => {
    const result = waiversTempResults?.find((result) => result.id === waiverResult.id);
    setIsChecked(result ? result.accepted === 'Y' : false);
  }, [waiversTempResults])

  React.useEffect(() => {
    setWaiver(waiverResult.waiver)
  }, [waiverResult])

  return (
    <Box sx={{ minWidth: 275, minHeight: 300 }}>
      <CardContent>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant='overline'>
            {waiverResult.case_id ? `Case ID: ${waiverResult.case_id}` : ''}
          </Typography>
          <Typography variant='h6' sx={{ marginLeft: 1 }}>
            {waiverResult.related_profile_name || waiverResult.related_profile_email}
          </Typography>
        </Box>
        <Typography variant='h6' component='div'>
          {waiver?.title}
        </Typography>
        <Paper sx={{ p: 1, maxHeight: 200, minHeight: 200, overflow: 'auto' }} variant='outlined'>
          <Typography sx={{ fontSize: 14, whiteSpace: 'pre-wrap' }} color='text.secondary' variant="body1" component="pre">
            {waiver?.copy}
          </Typography>
        </Paper>
        <CardActions>
          <FormControlLabel
            required={waiverResult.required}
            control={
              <Checkbox checked={isChecked} sx={{ marginTop: -0.5 }} onChange={handleOnChange} />
            }
            label={'Accept'}
          />
        </CardActions>
      </CardContent>
    </Box>
  )
}

export default Waiver