import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Dialog, DialogContent, DialogTitle, Button, TextField, CircularProgress } from '@mui/material';
import { Email, Lock } from '@mui/icons-material';
import { registerUser, verifyEmail } from '../../firebase/firebaseAuth';
import { LoginDialogsContext } from '../../context/loginDialogsContext';
import Typography from './Typography';


interface SignupFormData {
    email: string;
    password: string;
}

const initialButtonCopy = 'Register';

const Register = () => {
    const { showRegister, setShowRegister } = useContext(LoginDialogsContext);
    const { register, handleSubmit, formState: { errors }, setError } = useForm<SignupFormData>();
    const [buttonCopy, setButtonCopy] = useState(initialButtonCopy);

    const onSubmit = async (data: SignupFormData) => {
        try {
            const userCredential = await registerUser(data.email, data.password);
            if (userCredential) {
                console.log('User registered:', userCredential.user);
            }
            verifyEmail();
            // onClose();
            setButtonCopy('Check your email');
            setTimeout(() => {
                setButtonCopy(initialButtonCopy);
                setShowRegister(false);
            }, 3000);

        } catch (error: any) { // Add type annotation here
            setError('email', { message: error.message });
        }
    };

    return (
        <Dialog open={showRegister} keepMounted={false} onClose={() => setShowRegister(false)}>
            <DialogTitle>Healer Registration</DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <TextField
                        fullWidth
                        label="Email"
                        variant="outlined"
                        margin="normal"
                        {...register('email', { required: 'Email is required', pattern: /^\S+@\S+$/i })}
                        error={!!errors.email}
                        helperText={errors.email?.message}
                        InputProps={{
                            startAdornment: <Email sx={{ mr: 2 }} />,
                        }}
                    />
                    <TextField
                        fullWidth
                        label="Password"
                        type="password"
                        variant="outlined"
                        margin="normal"
                        {...register('password', { required: 'Password is required', minLength: 6 })}
                        error={!!errors.password}
                        helperText={errors.password?.message}
                        InputProps={{
                            startAdornment: <Lock sx={{ mr: 2 }} />,
                        }}
                    />

                    <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        fullWidth
                        style={{ marginTop: '16px' }}
                    >
                        Register
                    </Button>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default Register;
