import { AutoGraph } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'

export const LargeLogo = () => {
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Box sx={{ marginRight: 1, marginTop: 0.5 }}>
                <AutoGraph />
            </Box>
            <Typography sx={{ fontSize: 26 }}>
                Prana Records
            </Typography>
        </Box>

    )
}