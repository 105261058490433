import React, { Dispatch, SetStateAction, createContext, useEffect, useMemo } from 'react'

interface SearchContext {
    clientInfoSubstring: string | undefined
    setClientInfoSubstring: Dispatch<SetStateAction<string | undefined>>
    caseInfoSubstring: string | undefined
    setCaseInfoSubstring: Dispatch<SetStateAction<string | undefined>>
    sessionInfoSubstring: string | undefined
    setSessionInfoSubstring: Dispatch<SetStateAction<string | undefined>>
}

export const SearchContext = createContext<SearchContext>(undefined as any)

interface Props {
    children: React.ReactNode
}

export const SearchProvider: React.FC<Props> = ({ children }) => {
    const [clientInfoSubstring, setClientInfoSubstring] = React.useState<string | undefined>()
    const [caseInfoSubstring, setCaseInfoSubstring] = React.useState<string | undefined>()
    const [sessionInfoSubstring, setSessionInfoSubstring] = React.useState<string | undefined>()

    const values = {
        clientInfoSubstring,
        setClientInfoSubstring,
        caseInfoSubstring,
        setCaseInfoSubstring,
        sessionInfoSubstring,
        setSessionInfoSubstring
    }

    return <SearchContext.Provider value={values}>{children}</SearchContext.Provider>
}
