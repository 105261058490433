import { createTheme } from '@mui/material'
export const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          '&:hover': {
            opacity: 0.8,
          },
          '&:press': {
            color: 'grey',
          },
          '&:focused': {
            color: 'white',
          },
          '&:active': {
            color: 'white',
          },
        }),
      },
    },
  },
  typography: {
    button: {
      fontWeight: '600',
      fontSize: '12',
      color: 'white',
    },
  },
  palette: {
    warning: {
      main: '#F8D66D',
      dark: '#f7cf55',
      contrastText: 'white',
    },
    primary: {
      main: '#abc2d3',
      dark: '#8aa9c1',
      contrastText: 'white',
    },
    secondary: {
      main: '#8CD47E',
      dark: '#88d279',
      contrastText: 'white',
    },
    error: {
      main: '#FF6961',
      dark: '#ff564d',
      contrastText: 'white',
    },
  },
})
