const config = {
  apiKey: 'AIzaSyBl0MZ-GiDccgaLzDt_JTneO5igWrUM8NM',
  authDomain: 'madison-f29a6.firebaseapp.com',
  projectId: 'madison-f29a6',
  storageBucket: 'madison-f29a6.appspot.com',
  messagingSenderId: '203580954046',
  appId: '1:203580954046:web:43e1deeba012810f5ad5ef',
}

export function getFirebaseConfig() {
  if (!config || !config.apiKey) {
    throw new Error('No Firebase configuration object provided.')
  } else {
    return config
  }
}
