import { useContext, useEffect } from 'react'
import { WaiverContext } from '../../context/waiversContext'
import { WaiverResult } from '../../types'
import Waiver from './waiver'
import { Typography, Container, Paper, makeStyles } from '@mui/material';
import Welcome from '../Welcome';

const title = 'Hello';
const copy = 'You have no outstanding waivers';

const Waivers = () => {
  const { waiverResults } = useContext(WaiverContext);

  if (!waiverResults) {
    return <></>;
  }

  return (
    <>
      {waiverResults.length < 1 ? (
        <Welcome title={title} copy={copy} />) : (
        waiverResults.map((waiverResult: WaiverResult) => {
          return (
            <Waiver
              key={waiverResult.id}
              waiverResult={waiverResult}
            />
          )
        })
      )}
    </>
  )
}

export default Waivers