import React, { Dispatch, SetStateAction, createContext, useEffect, useMemo } from 'react'

interface LoginDialogsContext {
    showClientLogin: boolean;
    setShowClientLogin: Dispatch<SetStateAction<boolean>>;
    showHealerLogin: boolean;
    setShowHealerLogin: Dispatch<SetStateAction<boolean>>;
    showRegister: boolean;
    setShowRegister: Dispatch<SetStateAction<boolean>>;
    showResetPassword: boolean;
    setShowResetPassword: Dispatch<SetStateAction<boolean>>;
    showVerification: boolean;
    setShowVerification: Dispatch<SetStateAction<boolean>>;
}

export const LoginDialogsContext = createContext<LoginDialogsContext>(undefined as any)

interface Props {
    children: React.ReactNode
}

export const LonginDialogsProvider: React.FC<Props> = ({ children }) => {

    const [showClientLogin, setShowClientLogin] = React.useState(false);
    const [showHealerLogin, setShowHealerLogin] = React.useState(false);
    const [showRegister, setShowRegister] = React.useState(false);
    const [showResetPassword, setShowResetPassword] = React.useState(false);
    const [showVerification, setShowVerification] = React.useState(false);


    const values = {
        showClientLogin,
        setShowClientLogin,
        showHealerLogin,
        setShowHealerLogin,
        showRegister,
        setShowRegister,
        showResetPassword,
        setShowResetPassword,
        showVerification,
        setShowVerification,
    }

    return <LoginDialogsContext.Provider value={values}>{children}</LoginDialogsContext.Provider>
}
