import React, { Dispatch, SetStateAction, createContext, useEffect, useMemo } from 'react'
import { CaseStatusType, CaseType, SessionType } from '../types'
import { fiFI } from '@mui/x-date-pickers';
import _ from 'lodash';

type caseDialogStateType = {
  show?: boolean | undefined;
  clientId?: number | undefined;
}

type manageCaseDialogStateType = {
  show?: boolean | undefined;
  caseId?: number | undefined;
}

type sessionDialogStateType = {
  show?: boolean | undefined;
  clientId?: number | undefined;
  caseId?: number | undefined;
}

type CaseStatusDialogType = {
  show?: boolean | undefined;
  case?: CaseType;
  caseStatus?: CaseStatusType;
}

type ClientMenuStatusDialogType = {
  show?: boolean | undefined;
  clientId?: number | undefined;
}


type EditSessionDialogType = {
  show: boolean;
  currentSession: SessionType;
  sessionCase: CaseType;
}

interface DialogsContext {
  editSessionDialog: EditSessionDialogType;
  setEditSessionDialog: Dispatch<SetStateAction<EditSessionDialogType>>;
  showAddClientDialog: boolean;
  setShowAddClientDialog: Dispatch<SetStateAction<boolean>>

  caseDialogState: caseDialogStateType;
  setCaseDialogState: Dispatch<SetStateAction<caseDialogStateType>>
  showCaseDialog: ({ show, clientId }: caseDialogStateType) => void;

  manageCaseDialogState: manageCaseDialogStateType;
  setManageCaseDialogState: Dispatch<SetStateAction<caseDialogStateType>>
  showManageCaseDialog: ({ show, caseId }: manageCaseDialogStateType) => void;

  sessionDialogSate: sessionDialogStateType;
  setSessionDialogSate: Dispatch<SetStateAction<sessionDialogStateType>>
  showSessionDialog: ({ show, clientId, caseId }: sessionDialogStateType) => void;
  showPrivacyWaiverDialog: boolean;
  setShowPrivacyWaiverDialog: Dispatch<SetStateAction<boolean>>;
  showHealingWaiverDialog: boolean;
  setShowHealingWaiverDialog: Dispatch<SetStateAction<boolean>>
  showPublicNotice: boolean;
  setShowPublicNotice: Dispatch<SetStateAction<boolean>>;
  viewSessionConfig: { show: boolean; session: SessionType };
  setViewSessionConfig: Dispatch<SetStateAction<{ show: boolean; session: SessionType }>>
  caseStatusDialog: CaseStatusDialogType;
  setCaseStatusDialog: Dispatch<SetStateAction<CaseStatusDialogType>>;
  clientMenuDialog: ClientMenuStatusDialogType;
  setClientMenuDialog: Dispatch<SetStateAction<ClientMenuStatusDialogType>>;
}

export const DialogsContext = createContext<DialogsContext>(undefined as any)

interface Props {
  children: React.ReactNode
}

export const DialogsProvider: React.FC<Props> = ({ children }) => {
  const [editSessionDialog, setEditSessionDialog] = React.useState({
    show: false,
    currentSession: {} as SessionType,
    sessionCase: {} as CaseType,
  });

  const [showAddClientDialog, setShowAddClientDialog] = React.useState(false);

  const [sessionDialogSate, setSessionDialogSate] = React.useState<sessionDialogStateType>({
    show: false,
    clientId: undefined,
    caseId: undefined
  });
  const [caseDialogState, setCaseDialogState] = React.useState<caseDialogStateType>({
    show: false,
    clientId: undefined,
  });

  const [manageCaseDialogState, setManageCaseDialogState] = React.useState<caseDialogStateType>({
    show: false,
    clientId: undefined,
  });


  const showCaseDialog = ({ show, clientId }: caseDialogStateType) => {
    if (clientId && clientId !== undefined) {
      setCaseDialogState((prev) => ({ ...prev, show: show === undefined ? true : show, clientId: clientId }));
    } else {
      setCaseDialogState((prev) => ({ ...prev, show: show === undefined ? true : show }));
    }
  }

  const showManageCaseDialog = ({ show, caseId }: manageCaseDialogStateType) => {
    if (caseId && caseId !== undefined) {
      setManageCaseDialogState((prev) => ({ ...prev, show: show === undefined ? true : show, caseId: caseId }));
    } else {
      setManageCaseDialogState((prev) => ({ ...prev, show: show === undefined ? true : show }));
    }
  }

  const isUndefined = (value: any) => {
    if (value !== undefined) {
      return false;
    }
    return true;
  }

  const showSessionDialog = ({ show, clientId, caseId }: sessionDialogStateType) => {
    const data = {
      ...(isUndefined(show) ? {} : { show: show }),
      ...(isUndefined(clientId) ? {} : { clientId: clientId }),
      ...(isUndefined(caseId) ? {} : { caseId: caseId })
    }
    setSessionDialogSate((prev) => ({ ...prev, ...data }));
  }

  const [showPrivacyWaiverDialog, setShowPrivacyWaiverDialog] = React.useState(false);
  const [showHealingWaiverDialog, setShowHealingWaiverDialog] = React.useState(false);
  const [showPublicNotice, setShowPublicNotice] = React.useState(false);
  const [caseStatusDialog, setCaseStatusDialog] = React.useState<CaseStatusDialogType>({
    show: false,
    case: {} as CaseType,
    caseStatus: {} as CaseStatusType,
  });
  const [viewSessionConfig, setViewSessionConfig] = React.useState({
    show: false,
    session: {} as SessionType,
  });

  const [clientMenuDialog, setClientMenuDialog] = React.useState<ClientMenuStatusDialogType>({
    show: false,
    clientId: undefined,
  });


  const values = {
    editSessionDialog,
    setEditSessionDialog,
    sessionDialogSate,
    setSessionDialogSate,
    caseDialogState,
    setCaseDialogState,
    showAddClientDialog: showAddClientDialog,
    setShowAddClientDialog,
    showCaseDialog,
    manageCaseDialogState,
    setManageCaseDialogState,
    showManageCaseDialog,
    showSessionDialog,
    showPrivacyWaiverDialog,
    setShowPrivacyWaiverDialog,
    showHealingWaiverDialog,
    setShowHealingWaiverDialog,
    showPublicNotice,
    setShowPublicNotice,
    viewSessionConfig,
    setViewSessionConfig,
    caseStatusDialog,
    setCaseStatusDialog,
    clientMenuDialog,
    setClientMenuDialog
  }

  return <DialogsContext.Provider value={values}>{children}</DialogsContext.Provider>
}
