import React, { useContext, useState } from 'react';
import { useForm, Controller, FieldValues } from 'react-hook-form';
import { Avatar, Box, Button, Dialog, DialogContent, TextField, Typography } from '@mui/material';
import { loginUser, registerUser, resetPassword, verifyEmail } from '../../firebase/firebaseAuth';
import { LoginDialogsContext } from '../../context/loginDialogsContext';
import { Email } from '@mui/icons-material';


const AuthDialog: React.FC = () => {
    const { setShowHealerLogin, showHealerLogin, setShowResetPassword } = useContext(LoginDialogsContext);
    const { handleSubmit, control, getValues } = useForm();
    const [isLogin, setIsLogin] = useState(true);
    const [error, setError] = useState<string>('');
    const [successMessage, setSuccessMessage] = useState<string>('');

    const onSubmit = async (data: FieldValues) => {
        const { email, password } = getValues();
        setError('');
        setSuccessMessage('');

        try {
            if (isLogin) {
                await loginUser(email, password);
                setSuccessMessage('Login successful.');
            } else {
                await registerUser(email, password);
                await verifyEmail();
                setSuccessMessage('Registration successful.');
            }
        } catch (error: any) {
            setError(error.message);
        }
    };

    const handlePasswordReset = async (data: FieldValues) => {
        setError('');
        setSuccessMessage('');

        try {
            await resetPassword(data.email);
            setSuccessMessage('Password reset email sent. Please check your inbox.');
        } catch (error: any) {
            setError(error.message);
        }
    };

    return (
        <Dialog open={showHealerLogin} onClose={() => setShowHealerLogin(false)}>
            <DialogContent>
                <form onSubmit={handleSubmit(isLogin ? onSubmit : handlePasswordReset)} style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                    <Box sx={{ m: 'auto' }}>
                        <Avatar sx={{ bgcolor: 'secondary.main' }}>
                            <Email />
                        </Avatar>
                    </Box>

                    <Typography variant="h5" align="center">{isLogin ? 'Log In' : 'Register'}</Typography>
                    <Controller
                        name="email"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <TextField label="Email" {...field} variant="outlined" fullWidth />
                        )}
                    />
                    <Controller
                        name="password"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <TextField label="Password" type="password" {...field} variant="outlined" fullWidth />
                        )}
                    />
                    {isLogin ? (
                        <>
                            <Button type='submit' variant="contained" color="secondary" onClick={onSubmit}>
                                Log in
                            </Button>
                            <Typography align="center">
                                <span>Don&apos;t have an account? </span>
                                <span onClick={() => setIsLogin(false)} style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}>
                                    Register
                                </span>
                            </Typography>
                        </>
                    ) : (
                        <>
                            <Button type='submit' variant="contained" color="secondary" onClick={onSubmit}>
                                Register
                            </Button>
                            <Typography align="center">
                                <span>Already have an account? </span>
                                <span onClick={() => setIsLogin(true)} style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}>
                                    Log in
                                </span>
                            </Typography>
                        </>
                    )}
                    <Button onClick={() => setShowResetPassword(true)} color="secondary">
                        Forgot Password?
                    </Button>
                </form>
                {error && <Typography color="error" align="center">{error}</Typography>}
                {successMessage && <Typography color="primary" align="center">{successMessage}</Typography>}
            </DialogContent>
        </Dialog >
    );
};

export default AuthDialog;