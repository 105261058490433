import React, { ReactElement, useContext } from 'react'
import { AuthContext } from '../context/authContext'
import { Navigate, useLocation } from 'react-router-dom'
import { sendEmailVerification } from 'firebase/auth'
// import { verifyEmail } from '../firebase/firebaseAuth'

function RequireAuth({ children }: { children: ReactElement }) {
  const { currentUser } = useContext(AuthContext)
  const location = useLocation()
  // if (!currentUser) {
  //   return <Navigate to='/' state={{ from: location }} replace />
  // } else if (!currentUser.emailVerified) {
  //   console.log('trying to verify!!!');
  //   return <Navigate to='/' state={{ from: location }} replace />
  // }

  return children
}

export default RequireAuth
