import React, { createContext, useContext } from 'react';
import { useForm, SubmitHandler, UseFormReturn, FieldValues, UseFormWatch } from 'react-hook-form';
import { DateTime } from 'luxon';
import { UserContext } from '../../context/userContext';
import { partial } from 'lodash';
import { User } from '../../types';
import { useAxiosInstance } from '../../api/AxiosInstanceContext';

// Define the form data shape
export interface ProfileFormData extends User { };

// Define the context shape
interface FormContextType {
  formMethods: UseFormReturn<ProfileFormData>;
  onSubmit: () => void;
}

const ProfileFormContext = createContext<FormContextType | undefined>(undefined);

export const useFormContext = () => {
  const context = useContext(ProfileFormContext);
  if (!context) {
    throw new Error('useFormContext must be used within a ProfileFormProvider');
  }
  return context;
};

interface ProfileFormProviderProps {
  children: React.ReactNode;
}

export const ProfileFormProvider: React.FC<ProfileFormProviderProps> = ({ children }) => {
  const axiosInstance = useAxiosInstance();
  const { user, setData } = useContext(UserContext);
  const formMethods = useForm<ProfileFormData>();
  const onSubmit = async () => {
    const newUser = formMethods.getValues();

    const data = JSON.stringify({
      ...user,
      ...newUser,
    });
    axiosInstance.put('/profile/', data)
      .then((response) => {
        setData(response.data);
      }).catch((e) => {
        console.log('save user profile error: ' + e);
      });
  };

  return (
    <ProfileFormContext.Provider value={{ formMethods, onSubmit }}>
      {children}
    </ProfileFormContext.Provider>
  );
};
