import React, { createContext, useEffect, useMemo, useState } from 'react'
import { RecommendationType } from '../types'
import { useAxiosInstance } from '../api/AxiosInstanceContext'

interface RecommendationsContext {
    recommendations: RecommendationType[];
    recommendationsError: string | null;
    getRecommendation: (recommendationId: number) => RecommendationType | undefined;
    getRecommendations: (recommendationsIds: number[]) => (RecommendationType | undefined)[];
}

export const RecommendationsContext = createContext<RecommendationsContext>(undefined as any)

interface Props {
    children: React.ReactNode
}

export const RecommendationsProvider: React.FC<Props> = ({ children }) => {
    const axiosInstance = useAxiosInstance();
    const [recommendations, setRecommendations] = useState<RecommendationType[]>([] as RecommendationType[]);
    const [recommendationsError, setRecommendationsError] = useState<string | null>(null);

    useEffect(() => {
        axiosInstance.get('/recommendations')
            .then(({ data }) => setRecommendations(data))
            .catch((e) => setRecommendationsError(e));
    }, [])

    const getRecommendation = (recommendationId: number) => {
        if (recommendationId && recommendations && recommendations !== undefined) {
            return recommendations.find((c: RecommendationType) => c.id === recommendationId);
        }
    };

    const getRecommendations = (recommendationsIds: number[]) => {
        return recommendationsIds.map((index) => getRecommendation(index));
    };

    const values = {
        recommendations: recommendations ? recommendations : [],
        recommendationsError,
        getRecommendation,
        getRecommendations,
    };

    return <RecommendationsContext.Provider value={values}>{children}</RecommendationsContext.Provider>
};
