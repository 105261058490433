export default {
    title: 'Public Notice',
    copy:
        `PRANIC HEALING® practitioners are not licensed physicians or surgeons -- unless they have already learned PRANIC HEALING® after receiving an MD degree.

PRANIC HEALING® treatments are not licensed by any state. They are  complementary to any state-licensed healing practices or services.

PRANIC HEALING® is a highly developed system of energy based healing techniques that utilizes prana to balance, harmonize and transform the body's energy system by cleansing, energizing and balancing the body's bio-electromagnetic field, or aura.

PRANIC HEALING® practitioners do not physically touch the client's body, diagnose diseases, prescribe any drugs/substances or make any health claims or guarantee any outcomes.`
}