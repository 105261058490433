import { Dispatch, FC, ReactNode, SetStateAction, createContext, useEffect, useState } from 'react';
import { stored } from '../constants';

export type CasesFiltersType = {
    states: string[];
}

interface FiltersContext {
    setCasesFilters: Dispatch<SetStateAction<CasesFiltersType>>;
    casesFilters: CasesFiltersType;
};

export const FiltersContext = createContext<FiltersContext>(undefined as any);

interface Props {
    children: ReactNode;
}

export const FiltersProvider: FC<Props> = ({ children }) => {
    const [casesFilters, setCasesFilters] = useState<CasesFiltersType>({ states: [] });

    useEffect(() => {
        const reviewCasesFilters = localStorage.getItem(stored.reviewCasesFilters);
        if (reviewCasesFilters)
            setCasesFilters(JSON.parse(reviewCasesFilters));
    }, []);

    const value = {
        setCasesFilters,
        casesFilters
    }
    return <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>
}