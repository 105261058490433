import { useContext, useState } from 'react';
import { ReviewCasesContext } from '../../../context/review/ReviewCasesContext';
import CaseRowItem from '../../CaseRowItem.tsx';
import { Box } from '@mui/material';
import { getClientCaseSessions, getDisplayCaseState } from '../../ClientsTab/ClientsTable';
import { ReviewSessionsContext } from '../../../context/review/ReviewSessionsContext';
import _ from 'lodash';
import { SearchContext } from '../../../context/searchContext';

const ReviewCasesTable = () => {
    const { reviewCases, filteredCases } = useContext(ReviewCasesContext);
    const { reviewSessions } = useContext(ReviewSessionsContext);
    const [openStatusRows, setOpenStatusRows] = useState<number[]>([]);
    const [openSessionRows, setOpenSessionRows] = useState<number[]>([]);
    const { caseInfoSubstring } = useContext(SearchContext);

    const toggleSessionRow = (id: number) => {
        if (openSessionRows.includes(id)) {
            setOpenSessionRows(_.remove(openSessionRows, (existingId) => existingId != id));
        } else {
            setOpenSessionRows([...[id], ...openSessionRows]);
        }
    };

    const toggleStatusRow = (id: number) => {
        if (openStatusRows.includes(id)) {
            setOpenStatusRows(_.remove(openStatusRows, (existingId) => existingId != id));
        } else {
            setOpenStatusRows([...[id], ...openStatusRows]);
        }
    };

    const casesDisplayed = () => caseInfoSubstring && filteredCases ? filteredCases : reviewCases;

    return (
        <>
            {casesDisplayed().map((reviewCase, i, row) => {
                const rowCaseSessions = getClientCaseSessions(reviewSessions, reviewCase);
                return (
                    <Box sx={{ backgroundColor: 'whitesmoke' }} key={reviewCase.id}>
                        <CaseRowItem
                            rowCase={reviewCase}
                            rowCaseSessions={rowCaseSessions}
                            toggleSessionRow={toggleSessionRow}
                            toggleStatusRow={toggleStatusRow}
                            openStatusRows={openStatusRows}
                            openSessionRows={openSessionRows}
                        />
                        {i + 1 !== row.length && <Box sx={{ height: 10, backgroundColor: 'white' }} />}
                    </Box>
                )
            }
            )}
        </>
    )
}

export default ReviewCasesTable;