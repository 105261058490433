import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Email } from '@mui/icons-material'
import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { UserContext } from '../../context/userContext'
import { AuthContext } from '../../context/authContext'
import ProfileData from '../ProfileData'
import { Box } from '@mui/system'
import { useForm } from 'react-hook-form'
import { useFormContext } from '../ProfileData/ProfileFormContext'

export default function UserMenu({
  open,
  setOpen,
}: {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const [openEditProfile, setOpenEditProfile] = React.useState(false);
  const { user } = React.useContext(UserContext);
  const { signOut } = React.useContext(AuthContext);
  const { onSubmit } = useFormContext();

  const handleClose = () => {
    setOpen(false)
  }

  const descriptionElementRef = React.useRef<HTMLElement>(null)
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
        fullWidth
      >
        <Grid container sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
          <Grid item>
            <DialogContent>
              <DialogTitle id='scroll-dialog-title' sx={{ m: 1.8, p: 0, fontSize: 28 }}>
                {user.full_name}
              </DialogTitle>
              <DialogContentText id='scroll-dialog-title' sx={{ m: 1.8, p: 0 }}>
                {user.email}
              </DialogContentText>
            </DialogContent>
          </Grid>
        </Grid>
        <Divider />
        <DialogContent>
          <List
            sx={{ width: '100%', bgcolor: 'background.paper' }}
            component='nav'
            aria-labelledby='nested-list-subheader'
          >
            <ListItemButton onClick={() => setOpenEditProfile(!openEditProfile)}>
              <ListItemText primary='Profile Details' />
            </ListItemButton>
            <ListItem>
              <ProfileData />
            </ListItem>
          </List>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
          >
            <Button onClick={onSubmit}>Save</Button>
            <Button onClick={signOut}>Sign Out</Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  )
}
