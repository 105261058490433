import * as React from 'react'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import {
    Box,
    Button,
    Divider,
    Grid,
    useMediaQuery,
} from '@mui/material'
import { ClientsContext } from '../context/clientsContext'
import { DialogsContext } from '../context/dialogsContext'
import { Controller, useForm } from 'react-hook-form'
import { User } from '../types'
import { LoadingButton } from '@mui/lab'
import ConfirmDialog from './ConfirmDialog'
import { theme } from './SessionsTab/AreaStateSetterStyle'

export default function ClientMenuDialog() {
    const { clientMenuDialog, setClientMenuDialog } = React.useContext(DialogsContext);
    const { clients, updateClient, removeClient, refreshClients } = React.useContext(ClientsContext);
    const [loading, setLoading] = React.useState(false);
    const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);
    const [client, setClient] = React.useState<User | null>(null);
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

    React.useEffect(() => {
        if (clientMenuDialog.clientId) {
            const tempClient = clients.find((c) => c.id === clientMenuDialog.clientId);
            if (tempClient) {
                setClient(tempClient);
            }
        }
    }, [clientMenuDialog, clients])

    const { control, setValue, getValues } = useForm();

    const handleClose = () => {
        setClientMenuDialog({ show: false, clientId: undefined });
    }

    const handleUpdate = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);

        const { email } = getValues();
        const tempClient = clients.find((c) => c.id === clientMenuDialog.clientId);
        if (tempClient && client) {
            const updateData = {
                clientId: client.id,
                clientEmail: email,
            }
            await updateClient(updateData);
            refreshClients();
            handleClose();
        }
        setLoading(false);
    }

    const handleRemove = async () => {
        setLoading(true);
        const tempClient = clients.find((c) => c.id === clientMenuDialog.clientId);
        if (tempClient != null) {
            await removeClient(tempClient.id)
                .catch((e) => {
                    console.error('handleRemove client error: ' + e);
                    setLoading(false);
                })
            refreshClients();
            handleClose();
        }
        setLoading(false);
    }

    React.useEffect(() => {
        if (clientMenuDialog && clientMenuDialog.clientId) {
            const tempClient = clients.find((c) => c.id === clientMenuDialog.clientId);
            setValue('email', tempClient?.email || '');
        }
    }, [clientMenuDialog])


    return (
        <div>
            <Dialog open={!!clientMenuDialog.show} onClose={handleClose} keepMounted={false}>
                <DialogTitle>Update Client</DialogTitle>
                <Divider />
                <DialogContent sx={{ minWidth: isSmDown ? 300 : 500 }}>
                    <form onSubmit={(e) => handleUpdate(e)}>
                        {client?.tracked && (
                            <Grid item>
                                <Controller
                                    name='email'
                                    control={control}
                                    defaultValue=''
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label='Email'
                                            variant='standard'
                                            fullWidth
                                            margin='normal'
                                        />
                                    )}
                                />
                            </Grid>
                        )}
                        {client?.tracked && (<Box m={2} />)}
                        <Grid item>
                            {client?.tracked && (
                                <LoadingButton loading={loading} variant='contained' type='submit' color='primary' fullWidth>
                                    Save
                                </LoadingButton>
                            )}
                            <Box m={4} />
                            <Button variant='text' onClick={() => setShowConfirmDialog(true)} color='error' fullWidth>
                                Remove
                            </Button>
                        </Grid>
                    </form>
                </DialogContent>
            </Dialog>
            <ConfirmDialog
                open={showConfirmDialog}
                title="Warning"
                message="Deleting this Client record will also delete all Cases and Sessions linked to it, you can not undo this. Do you want to continue?"
                onConfirm={() => {
                    setShowConfirmDialog(false);
                    handleRemove();
                }}
                onCancel={() => {
                    setShowConfirmDialog(false);
                }}
            />
        </div>
    )
}
