import React, { Dispatch, SetStateAction, createContext, useEffect, useMemo, useState } from 'react'
import { AreaType } from '../types'
import { useAxiosInstance } from '../api/AxiosInstanceContext'
import { AxiosResponse } from 'axios'

interface AreasContext {
  areas: AreaType[]
  areasError: string | null
}

export const AreasContext = createContext<AreasContext>(undefined as any)

interface Props {
  children: React.ReactNode
}

export const AreasProvider: React.FC<Props> = ({ children }) => {
  const axiosInstance = useAxiosInstance();
  const [areas, setAreas] = useState<AreaType[]>([] as AreaType[]);
  const [areasError, setAreasError] = useState<string | null>(null);

  useEffect(() => {
    axiosInstance.get('/areas')
      .then((result) => {
        setAreas(result.data);
      }).catch((error) => {
        setAreasError(error);
      });
  }, [])

  const values = {
    areas: areas ? areas : [],
    areasError,
  }

  return <AreasContext.Provider value={values}>{children}</AreasContext.Provider>
}
