import React, { SetStateAction, createContext, useEffect, Dispatch, useState } from 'react'
import { CreatedAreaType } from '../types'
import { useAxiosInstance } from '../api/AxiosInstanceContext'

interface CreatedAreasContext {
  createdAreas: CreatedAreaType[]
  createdAreasError: string | null
  saveCreatedArea: (createdArea: Partial<CreatedAreaType>) => void
  saveCreatedAreaError: string | null
  setSaveCreatedAreaError: Dispatch<SetStateAction<string | null>>
}

export const CreatedAreasContext = createContext<CreatedAreasContext>(undefined as any)

interface Props {
  children: React.ReactNode
}

export const CreatedAreasProvider: React.FC<Props> = ({ children }) => {
  const axiosInstance = useAxiosInstance();
  const [data, setData] = useState<CreatedAreaType[] | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [savedCreatedArea, setSavedCreatedArea] = useState<CreatedAreaType | null>(null);
  const [saveCreatedAreaError, setSaveCreatedAreaError] = useState<string | null>(null);

  useEffect(() => {
    axiosInstance.get('/created_areas')
      .then((response) => {
        setData(response.data);
      }).catch((e) => setError(e));
  }, []);

  useEffect(() => {
    if (savedCreatedArea && data) {
      const newCreatedAreas = data.map((result: CreatedAreaType) =>
        result.id === savedCreatedArea.id ? savedCreatedArea : result,
      )
      setData(newCreatedAreas);
    }
  }, [savedCreatedArea]);

  const saveCreatedArea = (createdArea: Partial<CreatedAreaType>) => {
    if (createdArea) {
      axiosInstance.put('/created_area/', JSON.stringify(createdArea))
        .then((response) => {
          setSavedCreatedArea(response.data);
        })
        .catch((e) => {
          setSaveCreatedAreaError(e);
        });
    }
  }

  const value = {
    createdAreas: data ? (data as CreatedAreaType[]) : ([] as CreatedAreaType[]),
    createdAreasError: error,
    saveCreatedArea,
    saveCreatedAreaError,
    setSaveCreatedAreaError,
  }

  return <CreatedAreasContext.Provider value={value}>{children}</CreatedAreasContext.Provider>
}
